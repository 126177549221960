import { SERVER_BASE_URL } from "../config";
import axios from "axios";


export async function getOrders(useCache = true, page = 1, pageSize = 1000) {
  const storageKey = "store-orders";

  // Check if we should purge and load from localStorage or request from the server
  if (useCache && localStorage.getItem(storageKey)) {
    const storedData = JSON.parse(localStorage.getItem(storageKey));

    // Check if data is older than 1 hour
    const currentTime = new Date().getTime();
    if (currentTime - storedData.timestamp < 3600000) {  // 3600000 ms = 1 hour      
      console.log("orders loaded from localStorage");
      return storedData.results; // Return the data from localStorage
    } else {
      console.log("Cache expired, fetching new data");
    }
  }

  try {
    const token = localStorage.getItem("logToken")
    // Construct the request URL with pagination parameters
    const url = `${SERVER_BASE_URL}/orders/get?page=${page}&page_size=${pageSize}`;

    // Make the API request using axios
    const response = await axios.get(url, {
      headers: {
        'Authorization': `Token ${token}`,  // Replace with the actual token
        'Content-Type': 'application/json'
      }
    });

    // Save the fetched data to localStorage with timestamp
    const dataToStore = {
      results: response.data.results,
      timestamp: new Date().getTime()  // Save the timestamp
    };
    localStorage.setItem(storageKey, JSON.stringify(dataToStore));
    console.log("Saved to localStorage:", dataToStore);

    return response.data.results;  // Return the server data
  } catch (error) {
    console.error("Error fetching orders:", error);
    return null; // Handle error as needed
  }
}

