import { Button } from '@mui/material';
import React from 'react'
import { useTranslation } from 'react-i18next';

const CancelButton = ({ onClick, ...props }) => {
    const { t } = useTranslation();
    return (
        <Button
            variant="secondary"
            sx={{
                color: "var(--color1)",
                backgroundColor: "var(--color3)",                
                margin: "2%",
            }}
            {...props}
            onClick={onClick}
        >
            {t("cancel")}
        </Button>
    )
}

export default CancelButton