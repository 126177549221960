export const langResources = {
  en: {
    translation: {
      mobileLook: 'mobile',
      name: "name",
      email: "email",
      welcomeTitle: "Create a website for your business in 3 minutes",
      welcomeText1: "With Genericgs you can easily create a custom website,",
      welcomeText2: "for free!",
      welcomeText3: "Register and get impressed by sites examples and styles",
      exampleSite: "example site",
      login: "login",
      startHere: "start here",
      signup: "signup",
      loginText1: "login with your user",
      username: "user name",
      password: "password",
      logbut: "Enter",
      logerr: "wrong username/password. try again!",
      sigErr: "Password should be at least 4 characters",
      sigErr1: "username in use. try another one",
      defStoreName: "My Website",
      defVal: "Unknown",
      lastStage: "You are almost there..",
      lastStageMsg: "Enter the code we sent to your email",
      lastStageCode: "Enter code",
      cancel: "cancel",
      finish: "finish",
      emailConf: "You will need to verify your email",
      support: "support",
      logout: "logout",
      hello: "Hello",
      site: "my_site",
      mainPageTitle: "Welcome to GenericGS",
      mainPageT1:
        // "In this dashboard your can choose the site name, to add products to your site and another details as your open-hours, contacts way and more.",
        "Using this dashboard you can create your own website, where you can choose the site name, add products and other details",
      mainPageT2:
        "You can also choose the style and colors of your site as you wish.",
        mainPageT3: "With the menu appears on the ",
      mainPageT4: "right",
      mainPageT5: "top of the site ",
      mainPageT6: "you can see the different settings you can change.",
      mainPageT7:
        "It is easier to initial your website configuration using a PC rather via mobile .",
      mainPageT8: "For adding a payment mechanism to your site, contact us via",
      mainPageT9: "here",
      homeB1: "General settings",
      homeB2: "Add products",
      homeB3: "Open hours",
      homeB4: "Images",
      sucMsg: "Details updated successfully",

      // about
      s_name: "business name (also the site-name)",
      s_phone: "phone number",
      s_email: "email address (optional)",
      city: "city",
      street: "street name",
      s_num: "street number",
      s_desc: "business/store description",
      update: "update",
      updating: "updating...",

      // side nav
      my_p: "my products",
      images: "images",
      open_hours: "open hours",
      msg_news: "messages & news",
      links: "external links",
      orders: "orders",
      contacts: "contacts",
      contact: "contact",
      settings: "settings",
      my_acc: "my account",

      // products
      category: "category",
      free_search: "free search",
      cat_search: "filter by category",
      add_p: "add new product",
      mkt: "product id",
      p_name: "product name",
      stk: "in stock?",
      price: "price",
      mkt_uc: "product id (can't be changed later)",
      deleting: "deleting...",
      edit_title: "Edit product details",
      p_desc: "product's description",
      cat_ph: "e.g. breakfast, deserts, shoes",
      p_added: "New product created. now you can add images to it and edit it",
      add_p_title: "Enter new product details",
      // images
      p_ua: "image unavailable",
      add_i: "add new image",
      imgs_for: "images for ",
      img_added_msg: "image added successfully",
      chooseImg: "choose an image",

      // store images:
      aboutLogo: "Logo image",
      sec1: "secondary image 1",
      sec2: "secondary image 2",
      sec3: "secondary image 3",
      delete: "delete",
      a_main: "main image",

      // open hours
      oh_succ:
        "Your open-hours data was updated successfully, and is visible in your site",
        oh_ph: "What are your open hours?",
        
        // msgs
        gerr: "Sorry, the site got into error. please try again latter",
        msgTitle: "Messages & News",
        no_msg: "There are no messages in your site",
        create_msg: "create new message",
        edit_msg: "edit message",
        msg_title: "message's title",
        msg_cont: "message's content",
        alert_sure: "Are you sure?",
        // links
        linkTitle: "You can add external links to your site",
        tenb: "General link 1",
      fbL: "Link to Facebook",
      woltL: "Link to Wolt",
      instL: "Link to Instagram",
      waL: "Link to Whatsapp",

      // orders
      filterOrders: "filter by status",
      filterAllOrders: "all orders",
      finishOrders: "done orders",
      waitingOrders: "waiting orders",
      rejectOrders: "rejected orders",
      orderNum: "order ID",
      orderClient: "client name",
      status: "status",
      date: "date",
      noOrders: "you have no orders",
      ordDetails: "order details",
      orderComment: "order comments",
      clientTel: "client's tel",
      clientEmail: "clien's email",
      updateStatus: "update order status",
      done: "done",
      waiting: "waiting",
      rejected: "rejected",
      product: "product",
      amount: "amount",
      total: "total ",
      
      // contacts
      noContacts: "You have no contact messages",
      contactT1: "from",
      contactContent: "content",
      contactDone: "done?",
      generalSettings: "General Settings",
      genSetText1: "I am allowing costumers to make orders",
      genSetText2: "I want to verify my client emails",
      genSetText3: 'I am allowing "contact-me" form in my site',
      genSetText4: 'I am allowing "shipping" to orders',
      genSetText5: "I want to receive email alerts for",
      genSetText6: "new orders",
      genSetText7: "new contacts",
      colors: "colors",
      mainColor: "main color",
      secColor: "secondary color",
      col3: "buttons color",
      col4: "text color",
      resetColor: "default recommended color",
      genSetText8: "message after client sent order",

      // account
      chaneEmail: "change email",
      enterEmail: "Enter new email",
      sendingCode: "sending code ...",
      sendCode: "send verification code",
      checking: "checking...",
      confirm: "OK",
      changePsw: "change password",
      newPsw: "new password",
      rePsw: "confirm password",
      pwConstrain1: "password should contains at least 4 characters",
      pwConstrain2: "passwords are not identical",
      
      // contact System
      alert1: "please fill the content of your support request",
      sysSent: "support request sent!",
      sysTitle1: "write to us, and we will contact you ASAP",
      sending: "sending...",
      send: "send",
      
      //mobile:
      storeDetails: "general details",
      close: "close",
      
      // language
      language: "your site language",
      changeLangAlert:
        "Make sure that all messages are in English, (should be default)",
      
      logoImageDesc: 'A logo Image for your site header',
      
      mainImageTitle: 'Main image',
      mainImageDesc: 'The main image will be shown on top of your site main page',

      sec1ImageTitle: 'Another image',
      sec1ImageDesc: 'This image will also appear inside your site',

      storeImageBut: 'Add / Change',

      colorPel0: 'standard style',
      colorPel1: 'aggressive selling style',
      colorPel2: 'Influencing Opinions style',
      colorPel3: 'Informative style',      
      colorPel4: 'Gentle Site (General Purpose style)',
      colorsSettings: "Colors settings",
      errorCodeVerify: 'Wrong verification code. retry',
      wrongOldPw: 'wrong old password. retry',
      oldPsw: 'old password',
      sigErr2: 'there is a user with this email. retry',
      forgotPasswordTitle: 'Reset Password',
      enterEmailReset: 'enter your email',
      pwChanged: 'password changed successfully',
      forgotPw: 'forgot password?',
      emailNotFound: "Email not found. retry",
      wrongCode: 'wrong code. retry',
      resetPwCode: 'Enter the code we sent to your email',
      coinDesc: 'default currency',
      productDeleted: 'Product deleted successfully',
      imageDeleted: 'Image deleted successfully',
      save: 'save',
      alreadyUser: 'already have a user? login',
      newCostumer: 'new user? signup',
      name_missing: 'Product name is required field',
    },
  },
  /*
  {t('close')}
  t('gerr')
  const { t } = useTranslation();
  alert(t('sigErr'))
  */
 he: {  
   translation: {
     saved: "הנתונים התעדכנו בהצלחה",
     calendar: "ניהול יומן",
     calendarId: "מזהה יומן",     
     calendarInstructions: "הנחיות יומן",
     calendarInstructionsExample: "אפשר לקבוע פגישות רק בימים ראשון עד חמישי, בין 8 בבוקר ל11 בבוקר. אורך הפגישה יהיה לפחות 15 דקות, ועד שעה. עליך לשאול את הלקוח מה האורך הרצוי",
     calendarEndMsg: "הודעה לאחר קביעת אירוע",
     calendarEndMsgExample: "התור נקבע בהצלחה. אנו נשלח לך תזכורת ערב לפני. תודה ולהתראות :)",

     botOpenMessageTemplate: `צ'אט זה נתמך על ידי ביבוט. השימוש והמידע כפוף לתנאי השירות בקישור https://www.genericgs.com/tos`,
    botOpenMessage: "הודעת פתיחה לבוט",
     loadError: "אופס.. שגיאה אירעה בעת טעינת נתונים. אנא פנה לתמיכה או נסה שוב מאוחר יותר",
     loadProductsError: "אירעה שגיאה בטעינת המוצרים שלך. אנא פנה לתמיכה או נסה שנית מאוחר יותר",
    refresh: "רענן",
    clientPhone: "טלפון",
    searchOrders: "חיפוש חופשי",
     name_missing: 'עליך להזין את שם המוצר',
     newCostumer: 'לקוח חדש? הרשם',
     alreadyUser: 'משתמש קיים? התחבר',
     save: 'שמור',
     imageDeleted: 'התמונה נמחקה בהצלחה',
     productDeleted: 'המוצר נמחק בהצלחה',
     coinDesc: 'סוג מטבע למחירים',
     resetPwCode: 'הזן את הקוד שנשלח לאימייל שציינת',
     wrongCode: 'הקוד שהזנת שגוי. נסה שנית',
   emailNotFound: "האימייל שציינת לא קיים במערכת. נסה שנית",
     forgotPw: 'שכחת סיסמה?',
     pwChanged: 'הסיסמה נשמרה בהצלחה',
     enterEmailReset: 'הכנס את האימייל שנרשמת איתו',
     forgotPasswordTitle: 'שכחת סיסמה? אפס את סיסמתך באמצעות הטופס הבא',
     sigErr2: 'האימייל שציינת כבר תפוס אצל משתמש אחר. נסה שנית',
     oldPsw: 'סיסמה נוכחית',
     wrongOldPw: 'הסיסמה הקיימת שהוזנה איננה נכונה. נסה שנית',
     colorsSettings: "הגדרות צבעי האתר",
     
     mainImageTitle: 'תמונה ראשית',
     mainImageDesc: 'תמונה זו תופיע במרכז דף הבית שלך',
    
     sec1ImageTitle: 'תמונה נוספת',
     sec1ImageDesc: 'תמונה זו גם כן תופיע באתר',
    
     storeImageBut: 'להגדרת התמונה',
     mobileLook: 'מראה סמארטפון',
     contact: "contact",
      changeLangAlert: "כדאי לשנות גם את הודעת סיום הזמנה שתנוסח בעברית",
      language: "שפת הבוט שלך",
      close: "סגור",
      storeDetails: "פרטי העסק",
      send: "שלח",
      sending: "שולח...",
      sysTitle1: "כתוב לנו ונשתדל לענות בהקדם",
      sysSent: "הפניה נשלחה בהצלחה",
      alert1: "נא פרט את תוכן הפניה",
      pwConstrain2: "השדות צריכים להיות זהים",
      pwConstrain1: "על הסיסמה להכיל לפחות 4 תווים",
      rePsw: "הזן שוב",
      newPsw: "סיסמה חדשה",
      changePsw: "החלפת סיסמה",
      confirm: "אישור",
      checking: "בודק...",
      sendCode: "שלח קוד אימות",
      sendingCode: "שולח קוד...",
      enterEmail: "Enter new email",
      chaneEmail: "החלף אימייל",
      email: "email",
      genSetText8: "הודעה ללקוח בסיום הזמנה",            
      genSetText7: "פניות חדשות",
      genSetText6: "הזמנות חדשות",
      genSetText5: "קבל התראות לאימייל עבור",
      genSetText4: "אני מאפשר משלוח להזמנה",
      genSetText3: "אני מאפשר צור קשר",
      genSetText2: "אני דורש מהלקוח לאמת אימייל לפני הזמנה",
      genSetText1: "אני מאפשר ללקוחות לבצע הזמנות",
      allowCalendar: "אני מאפשר ללקוחות לקבוע פגישות ביומן",
      generalSettings: "הגדרות כלליות",
      contactDone: "טופל?",
      contactContent: "תוכן הפנייה",
      contactT1: "פנייה מאת",
      noContacts: "אין לך פניות מהאתר",
      total: ' סה"כ ',
      product: "מוצר",
      amount: "כמות",
      done: "הסתיימה",
      waiting: "בהמתנה",
      rejected: "נדחתה",
      updateStatus: "עדכן סטטוס הזמנה",
      clientEmail: "אימייל לקוח",
      clientTel: "טלפון לקוח",
      orderComment: "הערות הזמנה",
      ordDetails: "פרטי הזמנה",
      filterOrders: "סנן לפי סטטוס",
      filterAllOrders: "כל ההזמנות",
      finishOrders: "הזמנות שהסתיימו",
      waitingOrders: "הזמנות בהמתנה",
      rejectOrders: "הזמנות שבוטלו",
      orderNum: "מספר הזמנה",
      orderClient: "שם לקוח",
      status: "סטטוס",
      date: "תאריך",
      noOrders: "אין לך הזמנות",
      
      alert_sure: "בטוח למחוק?",
      msg_cont: "תוכן",
      msg_title: "כותרת",
      edit_msg: "עריכת הנחיה",
      add_msg: "הנחיית AI חדשה",
      create_msg: "צור הנחיה חדשה",
      no_msg: "לא מוגדרות הודעת",
      msgTitle: "הנחיות AI",
      gerr: "התנצלותנו. שגיאה התרחשה באתר. נא נסה שנית מאוחר יותר",
      oh_ph: "מהן שעות הפתיחה שלך?",
      oh_succ: "שעות הפתיחה התעדכנו וכעת הן מופיעות באתר",
      sec2: "תמונה משנית 2",
      sec3: "תמונה משנית 3",
      a_main: "תמונה ראשית",
      sec1: "תמונה משנית 1",
      aboutLogo: "תמונת לוגו",
      name: "שם",
      add_p_title: "הכנס פרטי מוצר חדש",
      p_added: "המוצר נוצר בהצלחה. כעת תוכל לערוך אותו ולהוסיף לו תמונות",
      chooseImg: "בחר תמונה",
      imgs_for: "תמונות ל ",
      img_added_msg: "התמונה התווספה בהצלחה, וכעת היא מופיעה באתר שלך",
      add_i: "הוסף תמונה",
      p_ua: "תמונה לא זמינה",
      cat_ph: "לדוגמה: מאפים | ארוחת בוקר | שתיה",
      p_desc: "תיאור המוצר",
      edit_title: "ערוך פרטי מוצר",
      deleting: "מוחק...",
      delete: "מחק",
      mkt_uc: "מקט (לא ניתן לשינוי )",
      price: "מחיר",
      stk: "במלאי",
      p_name: "שם המוצר",
      mkt: "מקט",
      add_p: "הוסף מוצר חדש",
      cat_search: "חפש לפי קטגוריה",
      free_search: "חיפוש חופשי",
      category: "קטגוריה",
      my_acc: "החשבון שלי",
      settings: "הגדרות נוספות",
      contacts: "פניות",
      orders: "הזמנות",
      links: "קישורים חיצוניים",
      msg_news: "הנחיות AI",
      open_hours: "שעות פתיחה",
      images: "תמונות",
      my_p: "המוצרים שלי",
      updating: "מעדכן...",
      update: "עדכן",
      s_desc: "תיאור העסק - כדאי להרחיב, על בסיס זה הבוט יכיר את העסק שלכם",
      s_num: "מספר",
      street: "רחוב",
      city: "עיר",
      s_email: " כתובת אימייל (אופציונלי - יוצג ללקוחות. אין זו כתובת האימייל הראשית שלך)",
      s_phone: "מספר טלפון",
      s_name: "שם העסק",
      sucMsg: "הנתונים התעדכנו בהצלחה",
      homeB1: "הגדרות כלליות",
      homeB2: "הוסף מוצרים",
      homeB3: "עדכן שעות פתיחה",
      homeB4: "הוסף תמונות",
      mainPageT1:
        "בדשבורד זה יש ביכולתך לבחור את שם האתר, את המוצרים שלך, ונתונים  נוספים כמו שעות פתיחה, או דרכי יצירת קשר.",
      mainPageT2: "אפשר לבחור גם את העיצוב והצבעים של האתר לפי רצונך.",
      mainPageT3: "בתפריט ",
      mainPageT4: "מימין",
      mainPageT5: " הנפתח למעלה ",
      mainPageT6: "ניתן לראות את רשימת הפעולות שאפשר להגדיר",
      mainPageT7: "ביצירת האתר לראשונה, מומלץ להתחבר דרך מחשב",
      mainPageT8: "במידה ותרצו להוסיף תמיכת סליקה דרך האתר, צרו איתנו קשר ",
      mainPageT9: "כאן",
      mainPageTitle: "וברוכים הבאים לג'נריקס",
      exampleSite: "אתר לדוגמה",
      site: "לאתר",
      hello: "שלום",
      logout: "התנתק",
      support: "תמיכה",
      emailConf: "תצטרך לאשר קוד שיישלח לך לאימייל",
      finish: "לסיום",
      cancel: "ביטול",
      lastStageCode: "הזן קוד",
      lastStageMsg: "לצורך סיום הרשמה, נא הזן את הקוד ששלחנו לך למייל",
      welcomeTitle: "בוא לבנות אתר לעסק שלך ב3 דקות",
      welcomeText1:
        "בעזרת ג'נריקס תוכל להשתמש בעיצובים קיימים וגם להכין אחד משלך.",
      welcomeText2: "חינם!!",
      welcomeText3: "בוא להתרשם ממגוון העיצובים והאתרים לדוגמה!",
      login: "התחברות",
      startHere: "התחל כאן",
      signup: "הרשמה",
      loginText1: "כניסה לרשומים",
      username: "שם משתמש",
      password: "סיסמה",
      logbut: "לחץ לכניסה",
      logerr: " שם משתמש וסיסמה שגויים. נסה שנית",
      sigErr: "נדרשת סיסמה באורך 4 תווים לפחות",
      sigErr1: "שם משתמש תפוס. בחר שם אחר",
      defStoreName: "אתר חדש - ללא שם",
      defVal: "לא ידוע",
     lastStage: "שלב אחרון",
     logoImageDesc: 'תמונה זו תופיע בכותרת האתר',
      errorCodeVerify: 'הקוד שהוזן שגוי. נסה שנית',
    },
  },
  fr: {
    translation: {
      name: "nom",
      email: "email",
      welcomeTitle: "Créez un site web pour votre entreprise en 3 minutes",
      welcomeText1:
        "Avec Genericgs, vous pouvez facilement créer un site web personnalisé,",
      welcomeText2: "gratuitement !",
      welcomeText3:
        "Inscrivez-vous et découvrez des exemples et des styles de sites impressionnants",
      exampleSite: "site exemple",
      login: "connexion",
      startHere: "commencez ici",
      signup: "inscrivez-vous",
      loginText1: "connectez-vous avec votre nom d'utilisateur",
      username: "nom d'utilisateur",
      password: "mot de passe",
      logbut: "Entrer",
      logerr: "nom d'utilisateur/mot de passe incorrect. Réessayez !",
      sigErr: "Le mot de passe doit comporter au moins 4 caractères",
      sigErr1: "nom d'utilisateur déjà utilisé. Choisissez-en un autre",
      defStoreName: "Mon site web",
      defVal: "Inconnu",
      lastStage: "vous y êtes déjà...",
      lastStageMsg:
        "Saisissez le code que nous avons envoyé à votre adresse e-mail",
      lastStageCode: "Saisissez le code",
      cancel: "annuler",
      finish: "terminer",
      emailConf: "Vous devrez vérifier votre adresse e-mail",
      support: "support",
      logout: "déconnexion",
      hello: "Bonjour",
      site: "mon_site",
      mainPageTitle: "Bienvenue sur GenericGS",
      mainPageT1:
        "Dans ce tableau de bord, vous pouvez choisir le nom du site, ajouter des produits à votre site et d'autres détails tels que vos horaires d'ouverture, les coordonnées, etc.",
      mainPageT2:
        "Vous pouvez également choisir le style et les couleurs de votre site comme bon vous semble.",
      mainPageT3: "Avec le menu qui apparaît sur la ",
      mainPageT4: "droite",
      mainPageT5: "haut du site,",
      mainPageT6:
        "vous pouvez voir les différents paramètres que vous pouvez modifier.",
      mainPageT7:
        "Il est plus facile d'initialiser la configuration de votre site web à l'aide d'un PC plutôt que via un mobile.",
      mainPageT8:
        "Pour ajouter un mécanisme de paiement à votre site, contactez-nous via",
      mainPageT9: "ici",
      homeB1: "Paramètres généraux",
      homeB2: "Ajouter des produits",
      homeB3: "Horaires d'ouverture",
      homeB4: "Images",
      sucMsg: "Détails mis à jour avec succès",
      s_name: "nom de l'entreprise (également le nom du site)",
      s_phone: "numéro de téléphone",
      s_email: "adresse e-mail (facultatif)",
      city: "ville",
      street: "nom de la rue",
      s_num: "numéro de rue",
      s_desc: "description de l'entreprise/magasin",
      update: "mettre à jour",
      updating: "mise à jour en cours...",
      my_p: "mes produits",
      images: "images",
      open_hours: "horaires d'ouverture",
      msg_news: "messages & actualités",
      links: "liens externes",
      orders: "commandes",
      contacts: "contacts",
      contact: "contact",
      settings: "paramètres",
      my_acc: "mon compte",
      category: "catégorie",
      free_search: "recherche libre",
      cat_search: "filtrer par catégorie",
      add_p: "ajouter un nouveau produit",
      mkt: "identifiant du produit",
      p_name: "nom du produit",
      stk: "en stock ?",
      price: "prix",
      mkt_uc:
        "identifiant du produit (ne peut pas être modifié ultérieurement)",
      deleting: "suppression en cours...",
      edit_title: "Modifier les détails du produit",
      p_desc: "description du produit",
      cat_ph: "par exemple, petit-déjeuner, desserts, chaussures",
      p_added:
        "Nouveau produit créé. Maintenant, vous pouvez y ajouter des images et le modifier",
      add_p_title: "Saisissez les détails du nouveau produit",
      p_ua: "image indisponible",
      add_i: "ajouter une nouvelle image",
      imgs_for: "images pour ",
      img_added_msg: "image ajoutée avec succès",
      chooseImg: "choisissez une image",
      aboutLogo: "Image du logo",
      sec1: "image secondaire 1",
      sec2: "image secondaire 2",
      sec3: "image secondaire 3",
      delete: "supprimer",
      a_main: "image principale",
      oh_succ:
        "Vos horaires d'ouverture ont été mis à jour avec succès et sont visibles sur votre site",
      oh_ph: "Quels sont vos horaires d'ouverture ?",
      gerr: "Désolé, une erreur est survenue sur le site. Veuillez réessayer plus tard",
      msgTitle: "Messages & Actualités",
      no_msg: "Il n'y a aucun message sur votre site",
      create_msg: "créer un nouveau message",
      edit_msg: "modifier le message",
      msg_title: "titre du message",
      msg_cont: "contenu du message",
      alert_sure: "Êtes-vous sûr(e) ?",
      linkTitle: "Vous pouvez ajouter des liens externes à votre site",
      tenb: "Lien général 1",
      fbL: "Lien vers Facebook",
      woltL: "Lien vers Wolt",
      instL: "Lien vers Instagram",
      waL: "Lien vers WhatsApp",
      filterOrders: "filtrer par statut",
      filterAllOrders: "toutes les commandes",
      finishOrders: "commandes terminées",
      waitingOrders: "commandes en attente",
      rejectOrders: "commandes refusées",
      orderNum: "ID de commande",
      orderClient: "nom du client",
      status: "statut",
      date: "date",
      noOrders: "Vous n'avez aucune commande",
      ordDetails: "détails de la commande",
      orderComment: "commentaires de la commande",
      clientTel: "téléphone du client",
      clientEmail: "e-mail du client",
      updateStatus: "mettre à jour le statut de la commande",
      done: "terminée",
      waiting: "en attente",
      rejected: "refusée",
      product: "produit",
      amount: "quantité",
      total: "total ",
      noContacts: "Vous n'avez aucun message de contact",
      contactT1: "de",
      contactContent: "contenu",
      contactDone: "fait ?",
      generalSettings: "Paramètres généraux",
      genSetText1: "J'autorise les clients à passer des commandes",
      genSetText2: "Je veux vérifier les adresses e-mail de mes clients",
      genSetText3: "J'autorise le formulaire 'contactez-moi' sur mon site",
      genSetText4: "J'autorise l'expédition des commandes",
      genSetText5: "Je souhaite recevoir des alertes e-mail pour",
      genSetText6: "nouvelles commandes",
      genSetText7: "nouveaux contacts",
      colors: "couleurs",
      mainColor: "couleur principale",
      secColor: "couleur secondaire",
      col3: "troisième couleur",
      col4: "quatrième couleur",
      resetColor: "réinitialiser aux couleurs recommandées par défaut",
      genSetText8: "message après l'envoi de la commande par le client",
      chaneEmail: "changer d'adresse e-mail",
      enterEmail: "Saisissez la nouvelle adresse e-mail",
      sendingCode: "envoi du code en cours...",
      sendCode: "envoyer le code de vérification",
      checking: "vérification en cours...",
      confirm: "OK",
      changePsw: "changer le mot de passe",
      newPsw: "nouveau mot de passe",
      rePsw: "confirmer le mot de passe",
      pwConstrain1: "le mot de passe doit contenir au moins 4 caractères",
      pwConstrain2: "les mots de passe ne correspondent pas",
      alert1: "veuillez remplir le contenu de votre demande de support",
      sysSent: "demande de support envoyée !",
      sysTitle1: "écrivez-nous et nous vous contacterons dès que possible",
      sending: "envoi en cours...",
      send: "envoyer",
      storeDetails: "détails généraux",
      close: "fermer",
      language: "langue de votre site",
      changeLangAlert:
        "Assurez-vous que tous les messages sont en anglais (devrait être la langue par défaut)",
    },
  },
  es: {
    translation: {
      name: "nombre",
      email: "correo electrónico",
      welcomeTitle: "Crea un sitio web para tu negocio en 3 minutos",
      welcomeText1:
        "Con Genericgs puedes crear fácilmente un sitio web personalizado,",
      welcomeText2: "¡gratis!",
      welcomeText3: "Regístrate y sorpréndete con ejemplos y estilos de sitios",
      exampleSite: "sitio de ejemplo",
      login: "iniciar sesión",
      startHere: "comienza aquí",
      signup: "registrarse",
      loginText1: "inicia sesión con tu usuario",
      username: "nombre de usuario",
      password: "contraseña",
      logbut: "Ingresar",
      logerr: "nombre de usuario/contraseña incorrectos. Inténtalo de nuevo.",
      sigErr: "La contraseña debe tener al menos 4 caracteres",
      sigErr1: "nombre de usuario en uso. Prueba otro",
      defStoreName: "Mi Sitio Web",
      defVal: "Desconocido",
      lastStage: "ya estás allí...",
      lastStageMsg: "Ingresa el código que te enviamos a tu correo electrónico",
      lastStageCode: "Ingresar código",
      cancel: "cancelar",
      finish: "terminar",
      emailConf: "Necesitarás verificar tu correo electrónico",
      support: "soporte",
      logout: "cerrar sesión",
      hello: "Hola",
      site: "mi_sitio",
      mainPageTitle: "Bienvenido a GenericGS",
      mainPageT1:
        "En este panel de control, puedes elegir el nombre del sitio, agregar productos a tu sitio y otros detalles como tu horario de apertura, formas de contacto y más.",
      mainPageT2:
        "También puedes elegir el estilo y los colores de tu sitio como desees.",
      mainPageT3: "Con el menú que aparece en el",
      mainPageT4: "derecha",
      mainPageT5: "superior del sitio,",
      mainPageT6: "puedes ver los diferentes ajustes que puedes cambiar.",
      mainPageT7:
        "Es más fácil iniciar la configuración de tu sitio web usando una computadora en lugar de un dispositivo móvil.",
      mainPageT8:
        "Para agregar un mecanismo de pago a tu sitio, contáctanos a través de",
      mainPageT9: "aquí",
      homeB1: "Configuración general",
      homeB2: "Agregar productos",
      homeB3: "Horario de apertura",
      homeB4: "Imágenes",
      sucMsg: "Detalles actualizados con éxito",
      s_name: "nombre del negocio (también el nombre del sitio)",
      s_phone: "número de teléfono",
      s_email: "dirección de correo electrónico (opcional)",
      city: "ciudad",
      street: "nombre de la calle",
      s_num: "número de la calle",
      s_desc: "descripción del negocio/tienda",
      update: "actualizar",
      updating: "actualizando...",
      my_p: "mis productos",
      images: "imágenes",
      open_hours: "horario de apertura",
      msg_news: "mensajes y noticias",
      links: "enlaces externos",
      orders: "pedidos",
      contacts: "contactos",
      contact: "contacto",
      settings: "configuraciones",
      my_acc: "mi cuenta",
      category: "categoría",
      free_search: "búsqueda libre",
      cat_search: "filtrar por categoría",
      add_p: "agregar nuevo producto",
      mkt: "ID del producto",
      p_name: "nombre del producto",
      stk: "en stock",
      price: "precio",
      mkt_uc: "ID del producto (no se puede cambiar después)",
      deleting: "eliminando...",
      edit_title: "Editar detalles del producto",
      p_desc: "descripción del producto",
      cat_ph: "por ejemplo, desayuno, postres, zapatos",
      p_added:
        "Nuevo producto creado. Ahora puedes agregar imágenes y editarlo",
      add_p_title: "Ingresa los detalles del nuevo producto",
      p_ua: "imagen no disponible",
      add_i: "agregar nueva imagen",
      imgs_for: "imágenes para",
      img_added_msg: "imagen agregada con éxito",
      chooseImg: "elige una imagen",
      aboutLogo: "Imagen del logotipo",
      sec1: "imagen secundaria 1",
      sec2: "imagen secundaria 2",
      sec3: "imagen secundaria 3",
      delete: "eliminar",
      a_main: "imagen principal",
      oh_succ:
        "Tus datos de horario de apertura se actualizaron con éxito y son visibles en tu sitio",
      oh_ph: "¿Cuál es tu horario de apertura?",
      gerr: "Lo siento, el sitio ha tenido un error. Por favor, inténtalo de nuevo más tarde",
      msgTitle: "Mensajes y Noticias",
      no_msg: "No hay mensajes en tu sitio",
      create_msg: "crear nuevo mensaje",
      edit_msg: "editar mensaje",
      msg_title: "título del mensaje",
      msg_cont: "contenido del mensaje",
      alert_sure: "¿Estás seguro?",
      linkTitle: "Puedes agregar enlaces externos a tu sitio",
      tenb: "Enlace general 1",
      fbL: "Enlace a Facebook",
      woltL: "Enlace a Wolt",
      instL: "Enlace a Instagram",
      waL: "Enlace a Whatsapp",
      filterOrders: "filtrar por estado",
      filterAllOrders: "todos los pedidos",
      finishOrders: "pedidos completados",
      waitingOrders: "pedidos en espera",
      rejectOrders: "pedidos rechazados",
      orderNum: "ID del pedido",
      orderClient: "nombre del cliente",
      status: "estado",
      date: "fecha",
      noOrders: "no tienes pedidos",
      ordDetails: "detalles del pedido",
      orderComment: "comentarios del pedido",
      clientTel: "teléfono del cliente",
      clientEmail: "correo electrónico del cliente",
      updateStatus: "actualizar estado del pedido",
      done: "completado",
      waiting: "en espera",
      rejected: "rechazado",
      product: "producto",
      amount: "cantidad",
      total: "total",
      noContacts: "No tienes mensajes de contacto",
      contactT1: "de",
      contactContent: "contenido",
      contactDone: "¿completado?",
      generalSettings: "Configuraciones generales",
      genSetText1: "Permito que los clientes realicen pedidos",
      genSetText2: "Quiero verificar los correos electrónicos de mis clientes",
      genSetText3: "Permito el formulario de 'contáctame' en mi sitio",
      genSetText4: "Permito el 'envío' para los pedidos",
      genSetText5: "Quiero recibir alertas por correo electrónico para",
      genSetText6: "nuevos pedidos",
      genSetText7: "nuevos contactos",
      colors: "colores",
      mainColor: "color principal",
      secColor: "color secundario",
      col3: "tercer color",
      col4: "cuarto color",
      resetColor: "restablecer al color recomendado por defecto",
      genSetText8: "mensaje después de que el cliente envíe el pedido",
      chaneEmail: "cambiar correo electrónico",
      enterEmail: "Ingresa un nuevo correo electrónico",
      sendingCode: "enviando código...",
      sendCode: "enviar código de verificación",
      checking: "verificando...",
      confirm: "OK",
      changePsw: "cambiar contraseña",
      newPsw: "nueva contraseña",
      rePsw: "confirmar contraseña",
      pwConstrain1: "la contraseña debe contener al menos 4 caracteres",
      pwConstrain2: "las contraseñas no son idénticas",
      alert1: "por favor completa el contenido de tu solicitud de soporte",
      sysSent: "solicitud de soporte enviada",
      sysTitle1: "escríbenos y te contactaremos lo antes posible",
      sending: "enviando...",
      send: "enviar",
      storeDetails: "detalles generales",
      close: "cerrar",
      language: "idioma de tu sitio",
      changeLangAlert:
        "Asegúrate de que todos los mensajes estén en inglés (debería ser el idioma predeterminado)",
    },
  },
  
  
};
