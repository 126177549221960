import React, { useContext, useEffect, useState } from "react";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { LoadingButton } from "../../helpers/LoadingButton";
import { updateStore } from "../../../helpers";
import { globalContext } from "../../../App";
import { useTranslation } from "react-i18next";
import toast from "react-hot-toast";
import "./Settings.css"

function Settings() {
  const [notifyOrder, setNotifyOrder] = useState(true);
  const [notifyContact, setNotifyContact] = useState(true);
  const [notifyCalendar, setNotifyCalendar] = useState(true);
  const [allowOrder, setAllowOrder] = useState(true);
  const [allowCalendar, setAllowCalendar] = useState(true);  
  const [allowContact, setAllowContact] = useState(true);
  const [allowShipping, setAllowShipping] = useState(true);
  const [updateButton, setUpdateButton] = useState(false) 

  const [OrderMsg, setOrderMsg] = useState("")
  const [openMsg, setOpenMsg] = useState("")
  const [language, setLanguage] = useState("")

  const { storeData, setStoreData } = useContext(globalContext);
  const { t } = useTranslation()

  useEffect(() => {
    if (storeData) {
     
      setNotifyOrder(storeData.notify_order);
      setNotifyContact(storeData.notify_contact);
      setNotifyCalendar(storeData.notify_calendar);
      setAllowOrder(storeData.allow_orders);
      setAllowContact(storeData.allow_contact);      
      setAllowCalendar(storeData.allow_calendar);
      setAllowShipping(storeData.allow_shipping);
      setOrderMsg(storeData.end_order_msg);
      setLanguage(storeData.language);
      setOpenMsg(String(storeData.bot_open_msg))

    }
  }, [storeData]);

  const handleSubmit = async () => {
    const data = {      
      notify_order: notifyOrder,
      notify_contact: notifyContact,
      notify_calendar: notifyCalendar,
      allow_orders: allowOrder,
      allow_contact: allowContact,
      allow_calendar: allowCalendar,      
      allow_shipping: allowShipping,
      end_order_msg: OrderMsg,
      bot_open_msg: openMsg,
      language,
    };

    await updateStore(data);
    // refetch storeData:
    const newStoreData = {
      ...storeData,     
      notify_order: notifyOrder,
      notify_contact: notifyContact,
      notify_calendar: notifyCalendar,
      allow_orders: allowOrder,
      allow_contact: allowContact,
      allow_calendar: allowCalendar,      
      allow_shipping: allowShipping,
      end_order_msg: OrderMsg,
      bot_open_msg: openMsg,
      language,
    };
    setStoreData(newStoreData);
    setUpdateButton(false)
  };

  const handleOpenMsgChange = (e) => {
    
      setOpenMsg(e.target.value);
      setUpdateButton(true)
    
  }
  return (
    <div style={{ maxWidth: '700px', margin: 'auto' }}>
      <h4> {t('generalSettings')} </h4>

      <span> {t("language")}  </span>
      <select value={language} style={{ margin: '2%' }} onChange={(e) => {
        setUpdateButton(true)
        setLanguage(e.target.value)
        // showAlert(setAlertData, t("changeLangAlert"));
        toast(t("changeLangAlert"), { style: { backgroundColor: 'var(--color2)' } })

      }}>
        <option value="en"> English </option>
        <option value="he" > עברית </option>
        <option value="fr" > France </option>
        <option value="es" > Español </option>
      </select>
      <br />
      <br />
      <div
        onClick={() => {
          setAllowOrder((p) => {
            return !p;
          });
          setUpdateButton(true)
        }}
      >
        {allowOrder && <CheckBoxIcon />}
        {!allowOrder && <CheckBoxOutlineBlankIcon />}
        <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
          {t('genSetText1')}
        </label>
      </div>
     
      <div
        onClick={() => {
          setAllowContact((p) => {
            return !p;
          });
          setUpdateButton(true)
        }}
      >
        {allowContact && <CheckBoxIcon />}
        {!allowContact && <CheckBoxOutlineBlankIcon />}
        <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
          {t('genSetText3')}
        </label>
      </div>
      
      <div
        onClick={() => {
          setAllowCalendar((p) => {
            return !p;
          });
          setUpdateButton(true)
        }}
      >
        {allowCalendar && <CheckBoxIcon />}
        {!allowCalendar && <CheckBoxOutlineBlankIcon />}
        <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
          {t('allowCalendar')}
        </label>
      </div>

      
      <br />
      <br />
      <div>
        <h4> {t('genSetText5')} </h4>
        <br />
        <div
          onClick={() => {
            setNotifyOrder((o) => {
              return !o;
            });
            setUpdateButton(true)
          }}
        >
          {notifyOrder && <CheckBoxIcon />}
          {!notifyOrder && <CheckBoxOutlineBlankIcon />}
          <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
            {t('genSetText6')}
          </label>
        </div>

        <div
          onClick={() => {
            setNotifyContact((o) => {
              return !o;
            });
            setUpdateButton(true)
          }}
        >
          {notifyContact && <CheckBoxIcon />}
          {!notifyContact && <CheckBoxOutlineBlankIcon />}
          <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
            {t('genSetText7')}
          </label>
        </div>
        <div
          onClick={() => {
            setNotifyCalendar((o) => {
              return !o;
            });
            setUpdateButton(true)
          }}
        >
          {notifyCalendar && <CheckBoxIcon />}
          {!notifyCalendar && <CheckBoxOutlineBlankIcon />}
          <label className="fl" style={{ display: "inline", marginLeft: "5%" }}>
            {/* {t('genSetText7')} */}
            פגישות חדשות ביומן מהלקוחות - בטא
          </label>
        </div>
        <br />
        <br />

        <h4> {t('genSetText8')} </h4>
        <textarea className="settings-msg" rows={10} value={OrderMsg} onChange={(e) => {
          setOrderMsg(e.target.value)
          setUpdateButton(true)
        }}>

        </textarea>
        <br />

        <h4> {t('botOpenMessage')} </h4>
        <div className="settings-open-msg-container">

        <textarea className="settings-msg sm-template"           
          rows={2}
            value={t('botOpenMessageTemplate')}
          disabled={true}
        />
        <textarea className="settings-msg sm-open-msg" rows={10}          
          value={openMsg}
          onChange={handleOpenMsgChange}          
        />
        </div>

        
        <br />

        <br />
        <LoadingButton
          onClick={handleSubmit}
          variant="contained"
          disabled={!updateButton}
          sx={{
            backgroundColor: "var(--color2)",
            width: "300px",
            maxWidth: "50%",
          }}
        >
          {t('update')}
        </LoadingButton>
      </div>
    </div>
  );
}

export default Settings;
