import React, { useContext, useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { updateStore } from "../../helpers";
import { LoadingButton } from "../helpers/LoadingButton";
import { globalContext } from "../../App";
import { useTranslation } from "react-i18next";


const coinOptions = () => {
  return <>
    <option value={"usd"}> &#36;  USD </option>
    <option value={"nis"}>  &#8362; NIS </option>    
    <option value={"pnd"}> &#163; POUND </option>
    <option value={"eur"}> &#8364; EURO </option>
  </>
}

function About(props) {
  const { storeData, refetchStoreData, setAlertData, alertData } =
    useContext(globalContext);
  const { t } = useTranslation();
  const [storeName, setStoreName] = useState(storeData ? storeData.name : "");

  const [storeTel, setStoreTel] = useState(
    storeData ? storeData.telephone : ""
  );
  const [storeEmail, setStoreEmail] = useState(
    storeData ? storeData.email : ""
  );
  const [storeDesc, setStoreDesc] = useState(
    storeData ? storeData.description : ""
  );

  const [coin, setCoin] = useState(storeData ? storeData.coin : "");
  const [city, setCity] = useState(storeData ? storeData.city : "");
  const [street, setStreet] = useState(storeData ? storeData.street : "");
  const [streetNum, setStreetNum] = useState(
    storeData ? storeData.street_number : ""
  );

  useEffect(() => {
    if (storeData) {
      setStoreName(storeData.name);
      setStoreTel(storeData.telephone);
      setStoreEmail(storeData.email);
      setStoreDesc(storeData.description);
      setCity(storeData.city);
      setStreet(storeData.street);
      setStreetNum(storeData.street_number);
      setCoin(storeData.coin);
    }
  }, [storeData]);

  const handleUpdateStore = async () => {
    await updateStore({
      name: storeName,
      city: city,
      street: street,
      country: "Israel",
      street_number: streetNum,
      email: storeEmail,
      description: storeDesc,
      telephone: storeTel,
      coin,
    });
    await refetchStoreData();
    const newAlertData = { ...alertData };
    newAlertData.msg = t("sucMsg");
    newAlertData.active = true;
    setAlertData({ ...newAlertData });
  };

  return (
    <>
      {storeData && (
        <>
          <div style={{ maxWidth: "900px", margin: "auto" }}>
            <Form style={{ margin: "4%" }}>
              <Form.Group className="mb-3">
                <Form.Label> {t("s_name")} </Form.Label>
                <Form.Control
                  required
                  type="text"
                  autoFocus
                  value={storeName}
                  onChange={(e) => {
                    setStoreName(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{t("s_phone")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="05"
                  value={storeTel}
                  onChange={(e) => {
                    setStoreTel(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>{t("s_email")} </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="name@example.com"
                  value={storeEmail}
                  onChange={(e) => {
                    setStoreEmail(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label> {t("city")} </Form.Label>
                <Form.Control
                  value={city}
                  onChange={(e) => {
                    setCity(e.target.value);
                  }}
                />
              </Form.Group>

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label> {t("street")} </Form.Label>
                <Form.Control
                  value={street}
                  onChange={(e) => {
                    setStreet(e.target.value);
                  }}
                />
              </Form.Group>

              <div style={{display: 'flex'}}>
                <div >
                  <label htmlFor="id_street"> {t("s_num")} </label>
                  <br></br>
                  <input
                    type="number"
                    style={{ fontSize: "1rem", maxWidth: "50%" }}
                    id="id_street"
                    value={streetNum}
                    onChange={(e) => {
                      setStreetNum(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label htmlFor="id_coin"> {t("coinDesc")} </label>
                  <br></br>
                  <select
                    style={{ fontSize: "1rem", }}
                    id="id_coin"
                    value={coin}
                    onChange={(e) => {                      
                      setCoin(e.target.value);
                    }}
                  >
                    {coinOptions()}
                  </select>
                </div>
              </div>

              <br />

              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlTextarea1"
              >
                <Form.Label> {t("s_desc")} </Form.Label>
                <Form.Control
                  as="textarea"
                  value={storeDesc}
                  rows={7}
                  onChange={(e) => {
                    setStoreDesc(e.target.value);
                  }}
                />
              </Form.Group>
            </Form>
            <div
              style={{
                direction: "ltr",
                display: "flex",
                marginLeft: "5%",
              }}
            >
              <LoadingButton
                variant="contained"
                color="success"
                sx={{
                  color: "var(--color1)",
                  backgroundColor: "var(--color2)",
                }}
                onClick={handleUpdateStore}
                loadingTxt={t("updating")}
              >
                {t("update")}
              </LoadingButton>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default About;
