// Welcome.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Welcome.css";
import BbotBot from "./BbotBot/BbotBot";
import { GS_BASE_URL } from "../../config";
import toast from "react-hot-toast";
import bbotLogo from '../../assets/bbotLogo.png';
import DemoChat from "./DemoChat/DemoChat";
import AnimatedDemoChat from "./animatedDemoChat/AnimatedDemoChat";
import DashboardDemo from "./dashboardDemo/DashboardDemo";


function Welcome(props) {
  const nav = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    telephone: "",
    email: "",
    content: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [disabledSend, setDisabledSend] = useState(false);

  const scrollToContact = () => {
    const contactSection = document.getElementById('contact');
    contactSection.scrollIntoView({ behavior: 'smooth' });
  };
  const handleLogin = () => {
    props.setShowWelcome(false);
    nav("/login");
  };
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    const { name, email, telephone, content } = formData;

    try {
      const response = await fetch(`${GS_BASE_URL}/contact/bbb`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, telephone, content }),
      });

      if (response.ok) {
        setSubmitStatus('Success');
        toast.success("הפרטים נשלחו בהצלחה! נציג יצור איתך קשר בהקדם")
        setDisabledSend(true);
      } else {
        setSubmitStatus('Error');
        toast.error("אירעה שגיאה, אנא נסו שוב")
      }
    } catch (error) {
      setSubmitStatus('Error');
      toast.error("אירעה שגיאה, אנא נסו שוב")
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="welcome-app">
      <nav className="welcome-navbar">
        <div className="welcome-nav-container">
          <div className="welcome-nav-logo">
            <img src={bbotLogo} alt="BBot Logo" style={{
              height: '40px', marginLeft: '10px', borderRadius: '50%'
            }} />
            <span className="welcome-logo">Bbot</span>

          </div>
          <button onClick={handleLogin} className="welcome-nav-login-button only-mobile">התחבר</button>
          <div className="welcome-nav-links">
            <button onClick={handleLogin} className="welcome-nav-login-button">התחבר</button>
            <a href="#features">תכונות</a>
            <a href="#how-it-works">איך זה עובד</a>
            <a href="#contact">צור קשר</a>
          </div>
            <button onClick={scrollToContact} className="welcome-nav-cta-button">התחל עכשיו</button>
        </div>
      </nav>

      <header className="welcome-hero">
        <div className="welcome-hero-container">
          <div className="welcome-hero-content">
            <h1 >קדם את העסק שלך עם תמיכת וואטסאפ מבוססת בינה מלאכותית</h1>
            <p>אוטומציה של שירות לקוחות, הגדלת מכירות ושיפור המעורבות עם BBot - העוזר האישי שלך ב-וואטסאפ 24/7.</p>
            <div className="welcome-hero-cta">              
              <BbotBot />              
            </div>
          </div>
        </div>
      </header>

      {/* Rest of the sections with updated class names */}
      <section id="features" className="welcome-features">
        <div className="welcome-section-container">
          <h2>למה לבחור ב-BBot?</h2>
          <div className="welcome-features-grid">
            <div className="welcome-feature-card">
              <i className="welcome-feature-icon robot"></i>
              <h3>טכנולוגיית בינה מלאכותית מתקדמת</h3>
              <p>הבינה המלאכותית שלנו מבינה הקשר ושפה טבעית, ומספקת אינטראקציות דמויות אנוש ללקוחות שלך.</p>
            </div>
            <div className="welcome-feature-card">
              <i className="welcome-feature-icon cart"></i>
              <h3>מכירות אוטומטיות</h3>
              <p>עיבוד הזמנות, טיפול בתשלומים והגדלת מכירות של מוצרים באופן אוטומטי דרך וואטסאפ.</p>
            </div>
            <div className="welcome-feature-card">
              <i className="welcome-feature-icon clock"></i>
              <h3>זמינות 24/7</h3>
              <p>ספק תמיכה מיידית ואל תפספס אף הזדמנות מכירה, גם מחוץ לשעות העבודה.</p>
            </div>
          </div>
        </div>
      </section>
      <section className="animated-demo-container">
        <AnimatedDemoChat/>
      </section>
      <section id="demo-chat" className="only-pc">
        <DemoChat kind="order"/>
        <DemoChat kind="contact"/>
        <DemoChat kind="calendar" />
      </section>
      <section className="only-pc">
        <div className="dashboard-demo-inner-container">
        <h2> דשבורד ניהולי מתקדם</h2>
        </div>
        <DashboardDemo/>
      </section>
      <section id="contact" className="welcome-contact">
        <div className="welcome-section-container">
          <div className="welcome-form-titles-container">
            <h2>צור קשר להצטרפות</h2>
            <p className="welcome-contact-intro">השאירו פרטים ונציג יחזור אליכם בהקדם להתחלת תהליך ההצטרפות ל-BBot</p>
          </div>
          <form onSubmit={handleSubmit} className="welcome-contact-form">
            <div className="welcome-form-grid">
              <div className="welcome-form-group">
                <label htmlFor="name">שם מלא *</label>
                <input
                  type="text"
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="welcome-form-group">
                <label htmlFor="telephone">טלפון *</label>
                <input
                  type="tel"
                  id="telephone"
                  name="telephone"
                  value={formData.telephone}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="welcome-form-group">
                <label htmlFor="email">אימייל</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                />
              </div>
            </div>
            <div className="welcome-form-group">
              <label htmlFor="content">הודעה *</label>
              <textarea
                id="content"
                name="content"
                value={formData.content}
                onChange={handleInputChange}
                required
                placeholder="ספר לנו קצת על העסק שלך ואיך BBot יכול לעזור לך"
              />
            </div>
            <button
              type="submit"
              className="welcome-submit-button"
              disabled={isSubmitting || disabledSend}
            >
              {isSubmitting ? "שולח..." : "שלח פרטים להצטרפות"}
            </button>
            {submitStatus === "success" && (
              <div className="welcome-status-message welcome-success">הפרטים נשלחו בהצלחה! נציג יצור איתך קשר בהקדם</div>
            )}
            {submitStatus === "error" && (
              <div className="welcome-status-message welcome-error">אירעה שגיאה, אנא נסו שוב</div>
            )}
          </form>
        </div>
      </section>

      <footer className="welcome-footer">
  <div className="welcome-footer-container">
    <div className="welcome-footer-content">
      <div className="welcome-footer-info">
        <h3>BBot</h3>
        <p>משנה עסקים עם פתרונות וואטסאפ<br />מבוססי בינה מלאכותית.</p>
      </div>
      <div className="welcome-footer-links">
        <div className="welcome-footer-section">
          <h4>משאבים</h4>
          <ul>
            <li><a href="#demo-chat">חקרי מקרה</a></li>
          </ul>
        </div>
        <div className="welcome-footer-section">
          <h4>משפטי</h4>
          <ul>
            <li><a onClick={() => { window.location = "/tos" }}>מדיניות פרטיות</a></li>
            <li><a onClick={() => { window.location = "/tos" }}>תנאי שימוש</a></li>
          </ul>
        </div>
      </div>
    </div>
    <div className="welcome-footer-bottom">
      <p>© 2024 BBot. כל הזכויות שמורות.</p>
    </div>
  </div>
</footer>

    </div>
  );
}

export default Welcome;