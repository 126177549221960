import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SERVER_BASE_URL } from "../../../config";
import { LoadingButton } from "../../helpers/LoadingButton";
import { globalContext } from "../../../App";
import { useTranslation } from "react-i18next";

function OpenHours(props) {
  const { storeData, refetchStoreData, alertData, setAlertData } = useContext(globalContext);
  const nav = useNavigate();
const {t} = useTranslation()
  const [openHours, setOpenHours] = useState(
    storeData ? storeData.open_hours : ""
  );

  useEffect(() => {
    if (storeData) {
      setOpenHours(storeData.open_hours);
    }
  }, [storeData]);

  const handleUpdate = async () => {
    const token = localStorage.getItem("logToken");
    const params = {
      open_hours: openHours,
    };

    const options = {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "PUT",
      body: JSON.stringify(params),
    };

    const res = await fetch(SERVER_BASE_URL + "/update_store", options);
    const resJ = res.json();
    await refetchStoreData();
    handleClose();
  };

  const handleClose = () => {
    
    const newAlertData = { ...alertData }
    newAlertData.msg = t('oh_succ')
    newAlertData.active = true
    setAlertData({...newAlertData})

    // nav("/");
  };

  return (
    <>
      {storeData && (
        <div style={{ margin: "auto", maxWidth: '600px' }}>
          <h4 className="fl"> {t('open_hours')} </h4>
          <br />
          <br />
          <textarea
            as="textarea"
            placeholder={t('oh_ph')}
            value={openHours}
            rows={9}
            style={{              
              width: "100%",
              maxWidth: "800px",
            }}
            onChange={(e) => {
              setOpenHours(e.target.value);
            }}
          />
          <br />          
          <LoadingButton
            sx={{ backgroundColor: "var(--color2)" }}
            variant="contained"
            onClick={handleUpdate}
            loadingTxt={t('updating')}
          >
            {t('update')}
          </LoadingButton>
        </div>
      )}
    </>
  );
}

export default OpenHours;
