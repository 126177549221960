import React from "react";

import "./home.css";
import { useTranslation } from "react-i18next";
import LangToggle from "../../LangToggle/LangToggle";

const Home = () => {  
  const { t } = useTranslation();
  
  return (
    <>
      <div>
        <LangToggle isInitialNeeded={false}/>
        <div className="home-container">
      <h1 className="home-title">לוח הבקרה שלך ב-Bbot </h1>
      
      <p className="home-description">
        ברוך הבא ללוח הבקרה האישי שלך ב-Bbot! כאן, תוכל לנהל בקלות את כל היבטי העסק שלך.
      </p>

      <div className="home-features">
        <h2>תכונות עיקריות:</h2>
        <ul>
          <li>
            <i className="fas fa-shopping-cart"></i>
            <span>ניהול מוצרים ושירותים</span>
          </li>
          <li>
            <i className="fas fa-robot"></i>
            <span>התאמה אישית של בוט בינה מלאכותית</span>
          </li>
          <li>
            <i className="fas fa-chart-line"></i>
            <span>מעקב אחר ביצועים</span>
          </li>
          <li>
            <i className="fas fa-headset"></i>
            <span>השתלטות על שיחות חיות</span>
          </li>
        </ul>
      </div>

     <h4> בתפריט מימין תוכל למצוא ולבצע את שלל הפעולות</h4>
    </div>
      </div>
    </>
  );
};

export default Home;
