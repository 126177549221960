import { SERVER_BASE_URL } from "../config";
import { compressImage } from "../utils/compress-image";

export const getPresignedUrlFromServer = async (mkt) => {
  try {
    const token = localStorage.getItem("logToken");

    // build options for getting presigned url
    const options = {
      headers: {
        Authorization: `Token ${token}`,
        // 'Accept': 'application/json',
        // 'Content-Type': 'text/plain;charset=UTF-8',
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({ mkt }),
    };

    // get presigned url
    const res = await fetch(SERVER_BASE_URL + "/product/image/add", options);

    // check if got presigned url
    if (!res.ok) {
      throw new Error("Failed to get presigned url");
    }

    // return presigned url
    return res.json();
  } catch (error) {
    console.error(error);
  }
};

export const uploadImageToS3 = async (presignedUrl, img) => {
  const compressedImg = await compressImage(img);

  if (!compressedImg) {
    // Todo: handle error if image not compressed
    return;
  }
  try {
    // build options for uploading image
    const options = {
      method: "put",
      headers: {
        Accept: "application/json",
        // "Content-Type": compressedImg.type,  // not works!
        "Content-Type": "image/png",
      },
      body: compressedImg,
    };

    console.log(compressedImg.type);
    
    // upload image to s3
    const res = await fetch(presignedUrl, options);

    // check if uploaded image
    if (res.status !== 200) {
      throw new Error("Failed to upload image");
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  } 
};
