import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import "./header.css";
import { globalContext } from "../../App";
import { fetchUserData, isTokenValid } from "../../helpers";
import logo from "../../assets/GenericStrore.png";
import { Button } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import MobileMenu from "../sideBar/MobileMenu";
import { useTranslation } from "react-i18next";

function Header() {
  const nav = useNavigate();

  const [showMMenu, setShowMMenu] = useState(false);
  const { t } = useTranslation();

  const {
    userLogged,
    setUserLogged,
    userData,
    setUserData,
    storeData,
    setShowWelcome,
    refetchStoreData
  } = useContext(globalContext);

  useEffect(() => {
    if (!userLogged) {
      // check if there is token
      const local_token = localStorage.getItem("logToken");
      if (!local_token) {
        console.log("Token not found. redirect to login page");
        nav("/login");
      }

      // check if token valid:  (must be a function since useEffect itself can't be async)
      const checkValid = async () => {
        const isValid = await isTokenValid(local_token, setUserLogged);
        if (!isValid) {
          nav("/login");
        } else {
          // fetch user's data
          fetchUserData().then(async (resUserData) => {
            setUserData(resUserData);

            // all correct.

            if (storeData) {
              console.log(storeData);              
            }
            // if storeData not fetched, lets do it:
            if (!storeData) {              
              await refetchStoreData();              
            } else {
              console.log("Page rendered with storeData");
            }
          });
        }
      };
      checkValid();
    }
  }, [userLogged]);

  const openMenu = () => {
    setShowMMenu((prev) => {
      return !prev;
    });
  };

  return (
    <>
      <nav className="navbar" dir="ltr">
        {userLogged && (
          <ul className="bar-list">
            <li>
              <img
                onClick={() => {
                  if (!userLogged) {
                    setShowWelcome(true)
                  }
                  nav("/")
                }}
                className="only-pc clickable header-logo"
                src={logo}
                width={"15%"}
                style={{ marginRight: "5px" }}
              />
            </li>
            <li>
              <a
                className="only-pc"
                style={{ textDecoration: "none", color: "inherit", marginLeft: '10px' }}
                onClick={() => {
                  nav("/contact-system");
                }}
              >
                {t("support")}
              </a>
            </li>
            <li className="only-mobile clickable" onClick={() => nav("/")}>
              Bbot
            </li>
            <li>
              <a
                className="only-pc"
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={() => {
                  localStorage.clear();
                  window.location = "";
                  nav("/");
                }}
              >
                {t("logout")}
              </a>
            </li>
            <li>
              <a
                className="only-pc"
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={() => {
                  nav("/account");
                }}
              >
                {t("hello")} {userData && userData.username}
              </a>
            </li>
          </ul>
        )}
        {/* <img
          onClick={() => {
            if (!userLogged) {              
              setShowWelcome(true)
            }
            nav("/")
          }}
          className="only-pc clickable"
          src={logo}
          width={"15%"}
          style={{ marginRight: "5px" }}
        /> */}
        <p onClick={() => {
          if (!userLogged) {
            setShowWelcome(true)
          }
          nav("/")
        }}
          className="only-pc clickable bbot-header-title"> Bbot </p>

        <div
          className="only-mobile clickable"
          style={{ margin: "5%", display: "inline" }}
        >
          {!userLogged && (
            <a
              className="only-mobile clickable"
              style={{ textDecoration: "none", color: "inherit" }}
              onClick={() => (window.location.href = window.location.href)}
            >
              Bbot
            </a>
          )}
          {userLogged && (
            <MenuIcon style={{ display: "inline" }} onClick={openMenu} />
          )}
        </div>
      </nav>

      {showMMenu && userLogged && (
        // Modal menu for mobile
        <MobileMenu showModal={showMMenu} setShowModal={setShowMMenu} />
      )}
    </>
  );
}

export default Header;
