import axios from 'axios';

import { SERVER_BASE_URL } from "../config";




// GET all categories
export async function getCategories() {
    const token = localStorage.getItem("logToken");
    const response = await fetch(SERVER_BASE_URL + "/category", {
        method: "GET",
        headers: {
            Authorization: `Token ${token}`,
            Accept: "application/json",
        },
    });
    return response.json();
}

// CREATE a new category
export async function createCategory(params) {
    const token = localStorage.getItem("logToken");
    const response = await fetch(SERVER_BASE_URL + "/category", {
        method: "POST",
        headers: {
            Authorization: `Token ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    });
    return response.json();
}

// UPDATE a category by ID
export async function updateCategory(params) {
    const token = localStorage.getItem("logToken");
    const response = await fetch(SERVER_BASE_URL + "/category", {
        method: "PUT",
        headers: {
            Authorization: `Token ${token}`,
            Accept: "application/json",
            "Content-Type": "application/json",
        },
        body: JSON.stringify(params),
    });
    return response.json();
}

export async function deleteCategory(categoryId) {
    const token = localStorage.getItem("logToken");
    try {
        const response = await axios.delete(`${SERVER_BASE_URL}/category`, {
            headers: {
                Authorization: `Token ${token}`,
                Accept: "application/json",
                "Content-Type": "application/json"
            },
            data: { "category_id": categoryId }
        });
        if (response.data.error === "products-attached") {
            return "products-attached";
        }
        return response.status === 204;
    } catch (error) {
        if (error.response.data.error === "products-attached") {
            return "products-attached";
        }
        console.error(error.response.data.error);
        return false;
    }
}

