import InfoIcon from "@mui/icons-material/Info";
import LocalGroceryStoreTwoToneIcon from "@mui/icons-material/LocalGroceryStoreTwoTone";
import WatchLaterTwoToneIcon from "@mui/icons-material/WatchLaterTwoTone";
import NewspaperTwoToneIcon from "@mui/icons-material/NewspaperTwoTone";
import ChatIcon from '@mui/icons-material/Chat';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CategoryIcon from '@mui/icons-material/Category';
import AirportShuttleTwoToneIcon from "@mui/icons-material/AirportShuttleTwoTone";
import ContactSupportTwoToneIcon from "@mui/icons-material/ContactSupportTwoTone";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import "./side-bar.css";
import { Settings } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function SideBar() {
  const location = useLocation();

  const { t } = useTranslation()

  return (
    <div
      className="only-pc"
      style={{ width: "20%", maxWidth: '300px', backgroundColor: "var(--color3)", minWidth: "200px" }}
    >
      <div
        style={{
          height: "75vh",
          borderBottom: "solid var(--color1)",
          marginTop: "5%",
        }}
      >
        <ul className="sb-ul">
          <li className="sb-li">
            <InfoIcon className="icon1" />
            <Link
              to={"/about"}
              className={
                location.pathname == "/about"
                  ? "sb-link sb-link-active"
                  : "sb-link"
              }
            >
              {t('homeB1')}
            </Link>
          </li>
          <li className="sb-li">
            <CategoryIcon className="icon1" />
            <Link
              to={"/categories"}
              className={
                location.pathname == "/links"
                  ? "sb-link sb-link-active"
                  : "sb-link"
              }
            >
              קטגוריות
            </Link>
          </li>
          <li className="sb-li">
            <LocalGroceryStoreTwoToneIcon className="icon1" />
            <Link
              to={"/products"}
              className={
                location.pathname == "/products"
                  ? "sb-link sb-link-active"
                  : "sb-link"
              }
            >
              {t('my_p')}
            </Link>
          </li>
          <li className="sb-li">
            <WatchLaterTwoToneIcon className="icon1" />
            <Link
              to={"/openhours"}
              className={
                location.pathname == "/openhours"
                  ? "sb-link sb-link-active"
                  : "sb-link"
              }
            >
              {t('open_hours')}
            </Link>
          </li>
          <li className="sb-li">
            <SmartToyIcon className="icon1" />
            <Link
              to={"/msgs"}
              className={
                location.pathname == "/msgs"
                  ? "sb-link sb-link-active"
                  : "sb-link"
              }
            >
              {t('msg_news')}
            </Link>
          </li>
          <li className="sb-li">
            <NewspaperTwoToneIcon className="icon1" />
            <Link
              to={"/calendar"}
              className={
                location.pathname == "/calendar"
                  ? "sb-link sb-link-active"
                  : "sb-link"
              }
            >
              {t('calendar')}
            </Link>
          </li>
          <li className="sb-li">
            <ChatIcon className="icon1" />
            <Link
              to={"/chats"}
              className={
                location.pathname == "/links"
                  ? "sb-link sb-link-active"
                  : "sb-link"
              }
            >
              צ'טבוט
            </Link>
          </li>
          
          <li className="sb-li">
            <AirportShuttleTwoToneIcon className="icon1" />
            <Link
              to={"/orders"}
              className={
                location.pathname == "/orders"
                  ? "sb-link sb-link-active"
                  : "sb-link"
              }
            >
              {t('orders')}
            </Link>
          </li>
          <li className="sb-li">
            <ContactSupportTwoToneIcon className="icon1" />
            <Link
              to={"/contacts"}
              className={
                location.pathname == "/contacts"
                  ? "sb-link sb-link-active"
                  : "sb-link"
              }
            >
              {t('contacts')}
            </Link>
          </li>
          <li className="sb-li">
            <Settings className="icon1" />
            <Link
              to={"/settings"}
              className={
                location.pathname == "/settings"
                  ? "sb-link sb-link-active"
                  : "sb-link"
              }
            >
              {t('settings')}
            </Link>
          </li>
        </ul>
      </div>
      <div
        style={{
          height: "20vh",
          textAlign: "center",
          padding: "10%",
        }}
      >
      </div>
    </div>
  );
}

export default SideBar;
