import { SERVER_BASE_URL } from "./config";
import axios from "axios";

export function getCategoryById(categoryId, storeData) {
  return storeData.categories.find(category => category.id === categoryId);
}

export async function isTokenValid(token, setFunc) {
  try {
    const res = await fetch(SERVER_BASE_URL + "/user_data", {
      headers: { Authorization: `Token ${token}` },
    });

    if (res.status !== 200) {
      console.log("Token not valid.");
      setFunc(false);
      return false;
    }
    await setFunc(true);
    return true;
  } catch (error) {
    window.alert(`Error checking token`);
    console.log(`Error checking token. error:`);
    console.log(error);
    return false;
  }
}

export async function fetchStoreData(setFunc) {
  const token = localStorage.getItem("logToken");
  const url = SERVER_BASE_URL + "/store_data";
  console.log(token);
  
  const _storeData = await axios.get(url, { headers: { Authorization: `Token ${token}` } });
  console.log(_storeData.data);
  return _storeData.data;

  return fetch(SERVER_BASE_URL + "/store_data", {
    headers: { Authorization: `Token ${token}` },
  })
    .then((res) => {
      res
        .json()
        .then((resJ) => {
          if (typeof resJ === "string") {
            // window.alert(`(125) Error ${resJ}`);
            setFunc({});
          } else {
            console.log(resJ);
            setFunc(resJ);
          }
        })
        .catch((e) => {
          // window.alert(`(123) Error ${e}`);
          throw e;
        });
    })
    .catch((e) => {
      window.alert(`(124) Error ${e}`);
    });
}

export async function fetchUserData() {
  const token = localStorage.getItem("logToken");
  const headers = {
    Authorization: `Token ${token}`,
  };

  const res = await fetch(SERVER_BASE_URL + "/user_data", {
    method: "GET",
    headers: headers,
  });

  const resJ = await res.json();

  if (res.status !== 200) {
    console.log("Error at fetching userData. res:");
    console.log(resJ);
    return;
  }
  console.log(resJ);
  return resJ;
}

export function deleteNotConfirmedUser(userId) {
  const url = SERVER_BASE_URL + "/email/delete";

  fetch(url, {
    method: "post",
    headers: {
      "Content-Type": "application/json", // what kind of answer I want back from the server
    },
    body: JSON.stringify({ user_id: userId }),
  })
    .then((res) => {
      res.json().then((resJ) => {
        console.log(`deleteEmailConfirmation: ${resJ}`);
      });
    })
    .catch((error) => {
      console.log("Error deleteNotConfirmedUser. error:");
      console.log(error);
    });
}

export function fetchProductsOfOrder(orderId, setFunc) {
  const token = localStorage.getItem("logToken");

  return fetch(SERVER_BASE_URL + `/order/products/${orderId}`, {
    headers: { Authorization: `Token ${token}` },
  })
    .then((res) => {
      res.json().then((resJ) => {
        setFunc(resJ);
        console.log("Fetched productsOrder. with:");
        console.log(resJ);
      });
    })
    .catch((e) => {
      console.log(`Error at fetching orderProducts. error: ${e}`);
    });
}


export async function deleteOrder(orderId) {
  const token = localStorage.getItem("logToken");
  return fetch(SERVER_BASE_URL + `/order/delete/${orderId}`, {
    headers: { Authorization: `Token ${token}` },
    method: "DELETE",
  })
    .then((res) => {
      res.json().then((resJ) => {
        console.log(resJ);
      });
    })
    .catch((e) => {
      window.alert(`Error at deleting order. error: ${e}`);
    });
}

export async function updateOrder(orderId, obj) {
  const token = localStorage.getItem("logToken");
  return fetch(SERVER_BASE_URL + `/order/update/${orderId}`, {
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    method: "PUT",
    body: JSON.stringify(obj),
  })
    .then((res) => {
      res.json().then((resJ) => {
        console.log("order status updated by following obj:");
        console.log(obj);
        console.log("Results from server:");
        console.log(resJ);
      });
    })
    .catch((e) => {
      window.alert(`Error at fetchng orderProducts. error: ${e}`);
    });
}

export async function updateStore(obj) {

  const token = localStorage.getItem("logToken");

  const params = obj;
  const options = {
    headers: {
      Authorization: `Token ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "PUT",
    body: JSON.stringify(params),
  };

  const res = await fetch(SERVER_BASE_URL + "/update_store", options)
  const resJ = await res.json()
  return resJ
}

export function showAlert(setAlertData, msg) {
  const alertData = {
    active: false,
    msg: "",
    btnMsg: "אישור",
    onClick: false,
  };
  const newAlertData = { ...alertData };

  newAlertData.msg = msg;
  newAlertData.active = true;
  setAlertData({ ...newAlertData });
}

export function isEmailValid(email) {
  if (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      )
  ) {
    return true;
  }
  return false;
}

export function coinMap(coin) {
  const data = {
    usd: "&#36;",
    nis: "&#8362;",
    eur: '&#163;',
    pnd: "&#8364;",
  }
  // return data[coin]
  const htmlEntity = data[coin];
  return <div style={{
    display: 'inline', color: 'inherit', backgroundColor: 'inherit',
    height: 'inherit',
  }} dangerouslySetInnerHTML={{ __html: htmlEntity }} />;
}