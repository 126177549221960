import { SERVER_BASE_URL } from "../config";
import { getPresignedUrlFromServer, uploadImageToS3 } from "./s3-client";
import axios from "axios";

export async function getProducts(useCache = true) {

  const token = localStorage.getItem("logToken")
  const storageKey = "store-products";

  // Check if we should purge and load from localStorage or request from the server
  if (useCache && localStorage.getItem(storageKey)) {
    const storedData = JSON.parse(localStorage.getItem(storageKey));

    // Check if data is older than 1 hour
    const currentTime = new Date().getTime();
    if (currentTime - storedData.timestamp < (24 * 3600000)) {  // 3600000 ms = 1 hour      
      return storedData.results; // Return the data from localStorage
    }

    console.log("Cache expired, fetching products from server");
  }

  try {
    // Construct the request URL with pagination parameters
    const url = `${SERVER_BASE_URL}/products`;
    
    let response = await axios.get(url, {
      headers: {
        'Authorization': `Token ${token}`,  // Replace with the actual token
        'Content-Type': 'application/json'
      }
    });
        
    if (typeof (response.data) == "string") { // If its empty list, its a string (why??)
      response.data =  JSON.parse(response.data);
    }
    
    // Save the fetched data to localStorage with timestamp
    
    const dataToStore = {
      results: response.data,
      timestamp: new Date().getTime()  // Save the timestamp
    };
    localStorage.setItem(storageKey, JSON.stringify(dataToStore));
    console.log("Prodcuts saved to localStorage:", dataToStore);

    return response.data;  
  } catch (error) {
    console.error("Error fetching products:", error);
    return null; // Handle error as needed
  }
}

export async function createProduct(obj) {
  if (
    !(
      ("mkt" in obj) &
      ("name" in obj) &
      // ("description" in obj) &
      ("price" in obj) &
      ("available" in obj)
    )
  ) {
    alert("Can't create product without all fields");
    return
  }

  const token = localStorage.getItem("logToken");

  const params = obj;

  const options = {
    headers: {
      Authorization: `Token ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    body: JSON.stringify(params),
  };

  try {
    const res = await fetch(SERVER_BASE_URL + "/create_products", options);
    res.json().then((resJ) => {
      console.log(resJ);
      if (resJ.includes("product added")) {
      } else {
        window.alert(`Error updating product: response: ${resJ}`);
      }
    });
  } catch (e) {
    window.alert(`Error updating product: error: ${e}`);
  }
}

export async function deleteProduct(mkt) {
  const token = localStorage.getItem("logToken");

  try {
    const response = await axios.delete(SERVER_BASE_URL + "/delete_products", {
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: { mkts: [mkt] }, // Use `data` for the request body with DELETE
    });

    if (response.data.includes("products deleted")) {
      return { status: "ok" };
    } else {
      return { status: "error", msg: `Error deleting product: ${response.data}` };
    }
  } catch (error) {
    if (error.response && error.response.data) {
      // Extract the server-provided error message
      return { status: "error", msg: error.response.data.info || "Unknown error" };
    } else {
      return { status: "error", msg: `Error deleting product: ${error.message}` };
    }
  }
}

export async function updateProduct(obj) {

  const token = localStorage.getItem("logToken");

  const params = obj;

  const options = {
    headers: {
      Authorization: `Token ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "PUT",
    body: JSON.stringify(params),
  };

  let res = ""
  let resJ = ""
  try {
    res = await fetch(SERVER_BASE_URL + "/update_product", options);
    console.assert(res.status == 200)
    resJ = await res.json();
    return true;

  } catch (error) {
    console.log(res)
    console.log(resJ)
    return false
  }
}

const _updateProductWithImageId = async (imageId) => {
  const token = localStorage.getItem("logToken");
  try {
    // build options:
    // Todo: get options from utils
    const options = {
      method: "put",
      headers: {
        Authorization: `Token ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ image_id: imageId, new_status: "ok" }),
    };

    // send request:
    // TODO: create class for fetch requests
    // consider using axios
    const res = await fetch(
      SERVER_BASE_URL + "/product/image/update_status",
      options
    );

    // check response:
    if (res.status !== 200) {
      throw new Error("Error at updating product with image id");
    }
  } catch (error) {
    console.error(error);
  }
};

export async function addProductImage(mkt, img) {
  const token = localStorage.getItem("logToken")
  try {

    // const compressedImg = await compressImage(img);
    // const imageType = 

    //  get presigned url from server:
    const preSignedUrlRes = await getPresignedUrlFromServer(mkt);


    if (!preSignedUrlRes) {
      // Todo: handle error if no presigned url
      return false;
    }

    const { psurl: presignedUrl, image_id: imageId } = preSignedUrlRes;

    // upload image to s3:
    const isUploaded = await uploadImageToS3(presignedUrl, img);

    // update server that image uploaded to s3:
    isUploaded && (await _updateProductWithImageId(imageId));

    if (!isUploaded) {
      console.log("isIploaded false!");
      return false;
    }

    return true;
    // Todo: handle error if image not uploaded to s3
  } catch (error) {
    console.log("Error adding image to product: ");
    console.log(error);
    return false;
  }
}

export async function deleteProductImage(imageId) {
  console.log(`About to fetch delete image of id: ${imageId}`);

  const token = localStorage.getItem("logToken");

  const options = {
    method: "delete",
    headers: {
      Authorization: `Token ${token}`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ image_id: imageId }),
  };

  const res = await fetch(SERVER_BASE_URL + `/product/image/delete`, options);
  if (res.status !== 200) {
    window.alert(`Error at delete image.`);
    console.log("Error at delete image. res:");
    console.log(res);
  }
  const resJ = await res.json();
}
