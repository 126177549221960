import React, { useContext, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { createCategory } from '../../../clients/categories-client';
import "./Categories.css";
import toast from 'react-hot-toast';
import { globalContext } from '../../../App';
import { LoadingButton } from '../../helpers/LoadingButton';
import { useTranslation } from 'react-i18next';
import CancelButton from '../../helpers/CancelButton';

const AddCategoryModal = ({ isOpen, onClose }) => {
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');

    const { refetchStoreData } = useContext(globalContext);
    const { t } = useTranslation();

    const handleSave = async () => {
        if (!name) {
            alert('Category name is required');
            return;
        }
        const newCategory = {
            name: name,
            description: description || ''
        };
        try {
            await createCategory(newCategory);
            await refetchStoreData();  // todo: don't use this!
            toast('הקטגוריה התווספה בהצלחה!');
            onClose();
        } catch (error) {
            alert('Failed to add category');
        }
    };

    if (!isOpen) return null;

    return (
        <Modal show={isOpen} onHide={onClose}>
            <Modal.Header>
                <h2>הוספת קטגוריה חדשה</h2>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <input
                        type="text"
                        className='form-control'
                        placeholder="שם הקטגוריה"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                    <br />
                    <textarea
                        className='form-control'
                        placeholder="תיאור הקטגוריה (אופציונאלי) "
                        rows={4}
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                    <br />
                    <LoadingButton onClick={handleSave} loadingTxt={t("updating")} >צור קטגוריה</LoadingButton>
                    {/* <button onClick={onClose} className="cancel">ביטול</button> */}
                    <CancelButton onClick={onClose}/>
                </div>
            </Modal.Body>
        </Modal>

    );
};

export default AddCategoryModal;
