import React, { useState, useContext } from 'react';
import AddCategoryModal from './AddCategoryModal';
import EditCategoryModal from './EditCategoryModal';
import { globalContext } from "../../../App";
import MainButton from '../../helpers/MainButton';


const Categories = () => {    
    const [isCreateModalOpen, setCreateModalOpen] = useState(false);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const { storeData } = useContext(globalContext);

    // Open create category modal
    const handleCreateCategory = () => {
        console.log(isCreateModalOpen);
        setCreateModalOpen(true);
    };

    // Open edit category modal
    const handleEditCategory = (category) => {
        setSelectedCategory(category);
        setEditModalOpen(true);
    };

    return (
        <div className='categories-container'>
            <h2 className='categories-title'>קטגוריות מוצרים</h2>
            <MainButton className='add-category' onClick={handleCreateCategory}>הוספת קטגוריה</MainButton>            

            <ul className='categories-list'>
                {storeData?.categories.map((category) => (
                    <li className='categories-li' key={category.id} onClick={() => handleEditCategory(category)}>
                        {category.name}
                    </li>
                ))}
            </ul>

            {isCreateModalOpen && (
                <AddCategoryModal
                    isOpen={isCreateModalOpen}
                    onClose={async () => {                       
                        setCreateModalOpen(false)
                    }}
                />
            )}

            {isEditModalOpen && selectedCategory && (
                <EditCategoryModal
                    isOpen={isEditModalOpen}
                    currentCategory={selectedCategory}
                    onClose={async () => {                        
                        setEditModalOpen(false);
                    }}
                />
            )}
        </div>
    );
};

export default Categories;