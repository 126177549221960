// ChatBot.jsx
import React, { useState, useEffect, useRef } from 'react';
import './BbotBot.css';
import { CircularProgress } from "@mui/material";
import toast from 'react-hot-toast';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import { BBOT_REST_URL } from '../../../config';

const BbotBot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [clientPhone, setClientPhone] = useState(localStorage.getItem('client_phone') || null);
    const [loading, setLoading] = useState(false);
    const messagesEndRef = useRef(null);

    useEffect(() => {
        // Load chat history from localStorage on initial load
        const savedMessages = JSON.parse(localStorage.getItem('chat_history')) || [];
        setChatMessages(savedMessages);
        scrollToBottom();
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [chatMessages]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    const toggleChatWindow = () => setIsOpen(!isOpen);

    const handleSend = async () => {
        if (message.trim() === '' || message.length > 500) {
            toast.error("הודעה ארוכה מידי. הצ'אט לא מיועד לשיחות נפש")
            return;
        }

        const newMessage = { type: 'visitor', content: message };
        const updatedChatMessages = [...chatMessages, newMessage];

        setLoading(true);
        setChatMessages(updatedChatMessages);
        localStorage.setItem('chat_history', JSON.stringify(updatedChatMessages));
        setMessage("")
        try {
            console.log(updatedChatMessages);

            const response = await fetch(BBOT_REST_URL + 'bbot-bot/new', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    new_message: message,

                    chat_history: updatedChatMessages
                        .map(msg => `${msg.type}: ${msg.content}`)
                        .join('\n'),

                    client_phone: clientPhone
                }),
            });

            const data = await response.json();
            const { client_phone, bbot_response } = data;

            if (client_phone) {
                localStorage.setItem('client_phone', client_phone);
                setClientPhone(client_phone);
            }

            const botResponse = { type: 'bot', content: bbot_response };

            const finalChatMessages = [...updatedChatMessages, botResponse];
            setChatMessages(finalChatMessages);
            localStorage.setItem('chat_history', JSON.stringify(finalChatMessages));
            setMessage('');
        } catch (error) {
            console.error("Error sending message", error);
        } finally {
            setLoading(false);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') handleSend();
    };

    return (
        <div className="chatbot-container">
            <button
                onClick={toggleChatWindow}
                className={`chatbot-toggle ${isOpen ? 'chatbot-toggle-open' : 'chatbot-toggle-closed'}`}
            >
                {isOpen ? 'סגור צ\'אט' : <> <ContactSupportIcon /> שאל אותי כל דבר </>}
            </button>

            {isOpen && (
                <div className="chatbot-window">
                    <div className="chatbot-header">
                        <h3>תמיכה בצ'אט</h3>
                    </div>

                    <div className="chatbot-messages">
                        <div className="messages-container">
                            {chatMessages.map((msg, index) => (
                                <div
                                    key={index}
                                    className={`message-wrapper ${msg.type}-wrapper`}
                                >
                                    <div className={`message ${msg.type}-message`}>
                                        {msg.content}
                                    </div>
                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>
                    </div>

                    <div className="chatbot-input-area">
                        <div className="input-container">
                            <input
                                type="text"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                onKeyPress={handleKeyPress}
                                maxLength="500"
                                placeholder="הקלד את ההודעה שלך..."
                            />
                            <button onClick={handleSend} disabled={loading}>
                                {loading ? <CircularProgress size={24} /> : 'שלח'}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BbotBot;
