import React from "react";
import "./DemoChat.css";


const getDemoByName = (name) => {
    switch (name) {
        case "order":
            return [
                {
                    from: "bot",
                    msg: "👋 היי! אני BBot, נשמח לעזור לך להתחיל את ההזמנה שלך",
                    time: "10:00",
                },
                {
                    from: "user",
                    msg: " היי, אני רוצה להזמין פיצה",
                    time: "10:01",
                },
                {
                    from: "bot",
                    msg: `בטח! איזה סוג פיצה תרצה? \n1. 🧀 גבינה - 40 ש"ח \n2. 🍕 פטריות - 50 ש"ח \n3. 🥦 ירקות - 45 ש"ח`,
                    time: "10:02",
                },
                {
                    from: "user",
                    msg: "פיצה קטנה עם גבינה",
                    time: "10:03",
                },
                {
                    from: "bot",
                    msg: `👍 מצוין! פיצה גבינה קטנה ב-40 ש"ח. תרצה להוסיף שתייה? \n1. 💧 מים - 5 ש"ח \n2. 🥤 קולה - 10 ש"ח`,
                    time: "10:04",
                },
                {
                    from: "user",
                    msg: "לא תודה",
                    time: "10:05",
                },
                {
                    from: "bot",
                    msg: `✅ מעולה! סה"כ ההזמנה שלך: 40 ש"ח. תודה רבה, נשלח לך אישור בקרוב!`,
                    time: "10:06",
                },
            ];
        case "contact":
            return [
                {
                    from: "bot",
                    msg: "👋 שלום! איך אוכל לעזור לך היום?",
                    time: "10:00",
                },
                {
                    from: "user",
                    msg: " רציתי לדווח על חוויה לא נעימה במסעדה אתמול",
                    time: "10:01",
                },
                {
                    from: "bot",
                    msg: "🙁 מצטער לשמוע על כך. תוכל בבקשה לשתף מה קרה כדי שנוכל לעזור?",
                    time: "10:02",
                },
                {
                    from: "user",
                    msg: "השירות היה איטי מאוד, והמנות הגיעו קרות",
                    time: "10:03",
                },
                {
                    from: "bot",
                    msg: "🔄 אני מתנצל על החוויה הזו. אדאג להעביר את הפרטים למנהל, ונחזור אליך בהקדם עם פתרון",
                    time: "10:04",
                },
                {
                    from: "user",
                    msg: "תודה רבה על הטיפול",
                    time: "10:05",
                },
                {
                    from: "bot",
                    msg: "😊 בשמחה! אנחנו כאן לכל שאלה נוספת",
                    time: "10:06",
                },
            ];
        case "calendar": 
            return [
                {
                    from: "bot",
                    msg: "📅 שלום! איך אוכל לעזור לך?",
                    time: "10:00",
                },
                {
                    from: "user",
                    msg: "אני רוצה לקבוע פגישה לטיפול פנים",
                    time: "10:01",
                },
                {
                    from: "bot",
                    msg: "👌 מצוין! באילו ימים ושעות אתה פנוי?",
                    time: "10:02",
                },
                {
                    from: "user",
                    msg: "יום שלישי בשעה 14:00",
                    time: "10:03",
                },
                {
                    from: "bot",
                    msg: "🔍 בודק... יש פגישה פנויה ביום שלישי בשעה 14:00. תרצה לאשר?",
                    time: "10:04",
                },
                {
                    from: "user",
                    msg: "כן, תודה",
                    time: "10:05",
                },
                {
                    from: "bot",
                    msg: "✅ הפגישה נקבעה בהצלחה ליום שלישי בשעה 14:00. נשלח לך תזכורת לפני הפגישה!",
                    time: "10:06",
                },
            ];
    }
};



const DemoChat = ({ kind }) => {
    const conversation = getDemoByName(kind);
    return (
      <div className="chat-container">
        <div className="chat-header">
          <div className="chat-title">
            {kind === "order" ? "הזמנת מוצר" : kind === "contact" ? "יצירת קשר" : "קביעת תור"}
          </div>
        </div>
        <div className="messages-wrapper">
          {conversation.map((msg, index) => (
            <div
              key={index}
              className={`message ${msg.from === "user" ? "user-message" : "bot-message"}`}
            >
              <div className="message-text">
                {msg.msg.split('\n').map((line, i) => (
                  <p key={i} className="message-line">{line}</p>
                ))}
              </div>
              <div className="message-time">{msg.time}</div>
            </div>
          ))}
        </div>
      </div>
    );
  };

export default DemoChat;