import React from 'react'
import "./SocketMsg.css"
const SocketMsg = ({ msg, index, pending }) => {

    if (pending) {
        return (
            <div key={`pending-${index}`} className="message business pending">
                <span className="message-content">{msg.content}</span>
                <span className="message-time">
                    {new Date(msg.created).toLocaleString("en-GB", {
                        day: "2-digit",
                        month: "2-digit",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                    })}
                </span>
                <p> {msg.llm_usage_input} / {msg.llm_usage_output}</p>
            </div>
        )
    }

    return (
        <div key={index} className={`message ${msg.sent_by}`}>            
            <span
                className="message-content"
                dangerouslySetInnerHTML={{
                    __html: msg.content.replace(/\n/g, "<br />")
                }}
            />
            <span className="message-time">
                {new Date(msg.created).toLocaleString("en-GB", {
                    day: "2-digit",
                    month: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit",
                    second: "2-digit",
                })}
            </span>
            {msg.sent_by === 'client' && <p className='msg-usage'> {msg.llm_usage_output}/{msg.llm_usage_input}</p>}
        </div>
    )
}

export default SocketMsg
