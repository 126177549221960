import React, { useContext, useState } from 'react';
import Modal from "react-bootstrap/Modal";
import { updateCategory, deleteCategory } from '../../../clients/categories-client';
import "./Categories.css";
import { globalContext } from '../../../App';
import toast from 'react-hot-toast';
import { LoadingButton } from '../../helpers/LoadingButton';
import { useTranslation } from 'react-i18next';
import CancelButton from '../../helpers/CancelButton';

const EditCategoryModal = ({ isOpen, onClose, currentCategory }) => {
    const [name, setName] = useState(currentCategory.name);
    const [description, setDescription] = useState(currentCategory.description);
    const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);

    const { refetchStoreData } = useContext(globalContext);
    const { t } = useTranslation();

    const handleSave = async () => {
        if (!name) {
            alert('Category name is required');
            return;
        }
        const updatedCategory = {
            ...currentCategory,
            category_id: currentCategory.id,
            name,
            description: description || ''
        };
        try {
            await updateCategory(updatedCategory);
            await refetchStoreData();
            toast('העדכון בוצע');
            onClose();
        } catch (error) {
            alert('Failed to update category');
        }
    };

    const handleDelete = async () => {
        try {
            const deleteError = await deleteCategory(currentCategory.id);

            if (deleteError === "products-attached") {
                toast.error("מוצרים מקושרים לקטגוריה. לא ניתן למחוק. אנא מחק את המוצרים קודם לכן")
                return;
            }

            if (deleteError) {
                await refetchStoreData();
                toast('הקטגוריה נמחקה בהצלחה!');
                onClose();
            } else {
                toast.error("שגיאה התרחשה. אנא פנה לתמיכה או נסה שוב מאוחר יותר")
            }
        } catch (error) {
            console.log(error);
            toast.error('שגיאה התרחשה. אנא פנה לתמיכה או נסה שנית מאוחר יותר');
        }
    };

    const confirmDelete = () => {
        setIsConfirmDeleteOpen(true);
    };

    const cancelDelete = () => {
        setIsConfirmDeleteOpen(false);
    };

    if (!isOpen) return null;

    return (
        <>
            <Modal show={isOpen} onHide={onClose}>
                <Modal.Header>
                    <h2>עריכת קטגוריה</h2>
                </Modal.Header>
                <Modal.Body className='cat-modal-dialog'>
                    <div>
                        <input
                            className='form-control'
                            type="text"
                            placeholder="Category Name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                        />
                        <textarea
                            className='form-control'
                            placeholder="Description (optional)"
                            value={description}
                            rows={6}
                            onChange={(e) => setDescription(e.target.value)}
                        ></textarea>
                        
                        <LoadingButton onClick={handleSave} loadingTxt={t("updating")} >עדכן</LoadingButton>
                        <LoadingButton onClick={handleDelete} loadingTxt={t("updating")} className="cat-delete">{t("delete")}</LoadingButton>
                        <CancelButton onClick={onClose} />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={isConfirmDeleteOpen} onHide={cancelDelete}>
                <Modal.Header>
                    <h2>Are you sure?</h2>
                </Modal.Header>
                <Modal.Dialog>
                    <div>
                        <p>האם אתה בטוח שברצונך למחוק את הקטגוריה?</p>
                        <p> אם ישנם מוצרים המקושרים לקטגוריה, לא ניתן למחוק אותה</p>
                        <button onClick={handleDelete} className="delete">מחק</button>
                        <button onClick={cancelDelete} >ביטול</button>
                    </div>
                </Modal.Dialog>
            </Modal>
        </>
    );
};

export default EditCategoryModal;
