import React, { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { LoadingButton } from "../../helpers/LoadingButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import { deleteContact, updateContactStatus } from "../../../clients/contacts-client";
import toast from "react-hot-toast";

function ContactModal(props) {
  const [spinner, setSpinner] = useState(false);
  const [status, setStatus] = useState(props.contact.status);

  const { t } = useTranslation()

  const handleDelete = async () => {
    try {
      const resJ = await deleteContact(props.contact.id);
      if (resJ.res === "ok") {
        await props.refetchContacts();
        props.setShowModal(false);
      } else {
        toast.error("saveError")
      }
    } catch (error) {
      console.error('Delete error:', error?.response?.data || error.message);
      toast.error("saveError")
    }
  };

  const handleStatusChange = async (id) => {
    const newStatus = !props.contact.status;
    try {
      const resJ = await updateContactStatus(id, newStatus);
      if (resJ.res === "ok") {
        await props.refetchContacts();
        setStatus(newStatus);
      } else {
        toast.error("saveError")
      }
    } catch (error) {
      console.error('Status update error:', error?.response?.data || error.message);
      toast.error("saveError")
    }
  };


  return (
    <>
      {props.contact.created && (
        <Modal show={props.showModal} onHide={() => props.setShowModal(false)}>
          <div className="modal-title1">
            <h4>{t('contactT1')} {props.contact.name}</h4>
            <CloseButton onClick={() => props.setShowModal(false)} />
          </div>
          <Modal.Body>
            <label className="fl"> {t('contactContent')}</label>
            <textarea
              className="form-control"

              disabled
              value={props.contact.content}
            />

            <label className="fl"> ID</label>
            <input className="form-control ltr" disabled value={props.contact.id} />

            <label className="fl"> {t('date')}</label>
            <input className="form-control" style={{ textAlign: 'left' }} disabled value={props.contact.created.slice(0, 10) + "   " + props.contact.created.slice(11, 16)} />

            <label className="fl"> Email </label>
            <input className="form-control" style={{ textAlign: 'left' }} disabled value={props.contact.email} />

            <label className="fl"> {t('clientTel')}</label>
            <input className="form-control" style={{ textAlign: 'left' }} disabled value={props.contact.telephone} />

            <label className="fl"> {t('contactDone')}</label>

            <div >
              {spinner && <div className="spinner-border text-success"> </div>}
              <input
                id={props.contact.id}
                style={{ marginRight: "10%" }}
                type="checkbox"
                checked={status}
                onChange={(e) => {
                  setSpinner(true);
                  handleStatusChange(props.contact.id).then(() => {
                    setSpinner(false);
                  });
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <LoadingButton
              variant="contained"
              color="error"
              onClick={handleDelete}
              loadingTxt={t('deleting')}
            >
              <DeleteIcon />
            </LoadingButton>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default ContactModal;
