import React, { useContext, useState } from "react";
import Msg from "./Msg";
import MsgModal from "./MsgModal";
import { SERVER_BASE_URL } from "../../../config";
import BackButton from "../../BackButton";
import { globalContext } from "../../../App";
import { Button } from "@mui/material";
import MainButton from "../../helpers/MainButton";

import "./msgs.css";
import { useTranslation } from "react-i18next";

function Msgs() {
  const { storeData, refetchStoreData } = useContext(globalContext);
  const [showNewMsg, setShowNewMsg] = useState(false);
  
  const {t} = useTranslation()
  const handleUpdate = async (id, title, content, method) => {
    const token = localStorage.getItem("logToken");
    const params = {
      store_id: storeData.id,
      id: id,
      title: title,
      content: content,
    };

    const options = {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: method,
      body: JSON.stringify(params),
    };

    console.log(`DB: id: ${id} content: ${content}`);
    const response = await fetch(SERVER_BASE_URL + "/messages", options);

    if (response.status !== 200) {
      window.alert(t('gerr'));
      console.log(response);
      return;
    }
    await refetchStoreData();
  };

  return (
    <>
      {storeData && (
        <div style={{'maxWidth': "700px", margin: 'auto' }}>
          <h3 style={{ color: "var(--color1)" }}> {t('msgTitle')} </h3>
                    

          <div style={{'maxWidth': "700px", margin: 'auto' }}>
            <br />
            {storeData.msgs.map((m) => 
                <div key={m.id} className="hover-pointer">
                  <Msg key={m.id} id={m.id} handleUpdate={handleUpdate} />
                </div>
              )
            }

            {storeData.msgs.length === 0 && <div>
              {t('no_msg')}
            </div>}
          </div>
        </div>
      )}
      <br/>
      <div style={{'maxWidth': "700px", margin: 'auto' }}>
        <MainButton
          onClick={() => {
            setShowNewMsg(true);
          }}
        >
          {t('create_msg')}
        </MainButton>
      </div>

      {showNewMsg && (
        <MsgModal
          showModal={showNewMsg}
          setShowModal={setShowNewMsg}
          handleUpdate={handleUpdate}
        />
      )}
    </>
  );
}

export default Msgs;
