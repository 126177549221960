import { createContext, useEffect, useState } from "react";
import { fetchStoreData, fetchUserData } from "./helpers";
import RouteSite from "./components/RouteSite";
import Header from "./components/Header/Header";
import "./style.css";
import SideBar from "./components/sideBar/SideBar";
import Welcome from "./components/welcome/Welcome";
import AlertModal from "./components/AlertModal";
import { useTranslation } from "react-i18next";
import toast, { Toaster } from "react-hot-toast";
import TermsOfService  from "./components/TermsOfService/TermsOfService";
export const globalContext = createContext(null);

function App() {
  const { t } = useTranslation();

  const [lang, setLang] = useState("he");
  // const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [showWelcome, setShowWelcome] = useState(true);
  const [userLogged, setUserLogged] = useState(false);
  const [storeData, setStoreData] = useState();
  const [userData, setUserData] = useState(false);
  const [isError, setIsError] = useState(false)

  const [alertData, setAlertData] = useState({
    active: false,
    msg: "",
    btnMsg: t("confirm"),
    onClick: false,
  });

  // skip welcome if token exist
  useEffect(() => {
    if (localStorage.getItem("logToken")) {
      setShowWelcome(false);
    }
    document.body.dir = "rtl";
    document.body.style.textalign = "right";
  }, []);

  const refetchStoreData = async () => {
    try {
      console.log("refetching store data");
      const _storeData = await fetchStoreData(setStoreData);
      setStoreData(_storeData);
    } catch (error) {
      toast.error(t("loadError"))
      setIsError(true);
    }
  };
  const refetchUserData = async () => {
    const res = await fetchUserData();
    console.log("refetching user data with:");
    console.log(res);
    setUserData(res);
  };

  if (isError) {
    return <div className="error-container">
      <p className="error-msg">{t("loadError")}</p>
    </div>
  }  

  if (window.location.pathname == "/tos"){
    return <TermsOfService/>
  }

  return (
    <>
      <Toaster></Toaster>

      {alertData && alertData.active && (
        <AlertModal alertData={alertData} setAlertData={setAlertData} />
      )}

      <globalContext.Provider
        value={{
          lang,
          setLang,
          userLogged,
          setUserLogged,
          storeData,
          setStoreData,
          refetchUserData,
          refetchStoreData,
          setUserData,
          userData,
          setAlertData,
          alertData,
          setShowWelcome,
        }}
      >
        {showWelcome && <Welcome setShowWelcome={setShowWelcome} />}
        {!showWelcome && (
          <>
            <Header />
            <div
              style={{
                display: "flex",
                flexDirection: lang !== "heb" ? "row" : "row-reverse",
              }}
            >
              {userLogged && <SideBar />}
              <div
                className="only-pc"
                style={{
                  width: "90%",
                  padding: "1%",
                }}
              >
                <br />
                <RouteSite />
              </div>
              {/* <IFrame/>               */}
              <div
                className="only-mobile"
                style={{
                  maxWidth: "100%",
                  minWidth: "100%",
                  padding: "2%",
                }}
              >
                <br />
                <RouteSite />
              </div>
            </div>
          </>
        )}
      </globalContext.Provider>
    </>
  );
}

export default App;
