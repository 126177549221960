import React, { useState, useEffect } from "react";
import "./DashboardDemo.css";

// Import images
import dashboardImage1 from '../../../assets/dashboard-image-1.png';
import dashboardImage2 from '../../../assets/dashboard-image-2.PNG';
import dashboardImage3 from '../../../assets/dashboard-image-3.PNG';
import dashboardImage4 from '../../../assets/dashboard-image-4.PNG';
import dashboardImage5 from '../../../assets/dashboard-image-5.PNG';

const DashboardDemo = () => {
  const images = [
    { src: dashboardImage1, size: { width: 888, height: 761 } },
    { src: dashboardImage2, size: { width: 352, height: 482 } },
    { src: dashboardImage3, size: { width: 1052, height: 462 } },
    { src: dashboardImage4, size: { width: 846, height: 723 } },
    { src: dashboardImage5, size: { width: 451, height: 673 } },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000); // Change image every 3 seconds
    return () => clearInterval(interval);
  }, [images.length]);

  return (
    <div className="dashboard-demo-container">
      {images.map((img, index) => (
        <div
          key={index}
          className={`dashboard-demo-frame ${
            index === currentIndex ? "active" : "inactive"
          }`}
          style={{
            width: img.size.width + "px",
            height: img.size.height + "px",
          }}
        >
          <img
            src={img.src}
            alt={`Dashboard ${index + 1}`}
            className="dashboard-demo-image"
          />
        </div>
      ))}
    </div>
  );
};

export default DashboardDemo;
