import React, { useState } from "react";
import { CloseButton, Modal } from "react-bootstrap";
import { LoadingButton } from "../../helpers/LoadingButton";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import CancelButton from "../../helpers/CancelButton";

function MsgModal(props) {
  const msg = props.msg ? props.msg : null;

  const [title, setTitle] = useState(msg ? msg.title : "");
  const [content, setContent] = useState(msg ? msg.content : "");
  const [showDelete, setShowDelete] = useState(false);
  const { t } = useTranslation();
  const handleSubmit = async () => {
    await props.handleUpdate(
      props.msg ? props.msg.id : null,
      title,
      content,
      props.msg ? "PUT" : "POST"
    );
    props.setShowModal(false);
  };

  return (
    <>
      <div style={{ color: "var(--color1)" }}>
        {!showDelete && (
          <Modal
            show={props.showModal}
            onHide={() => {
              props.setShowModal(false);
            }}
          >
            <div className="modal-title1">
              {!props.msg && <h4>{t("add_msg")}</h4>}
              {props.msg && <h4>{t("edit_msg")}</h4>}
              <CloseButton onClick={() => props.setShowModal(false)} />
            </div>

            <label className="label1"> {t("msg_title")} </label>
            <input
              value={title}
              className="form-control"
              style={{ margin: '0% 4%', width: '90%' }}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            <br />

            <label className="label1"> {t("msg_cont")}</label>
            <textarea
              value={content}
              rows={5}
              style={{
                margin: "auto",
                width: "90%",
                borderRadius: "10px",
              }}
              onChange={(e) => {
                setContent(e.target.value);
              }}
            />
            <br />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0 6%",
              }}
            >
              <div style={{ width: "50%" }}>
                <LoadingButton
                  variant="contained"
                  sx={{ backgroundColor: "var(--color2)" }}
                  onClick={handleSubmit}
                  loadingTxt={t("updating")}
                >
                  {t("update")}
                </LoadingButton>

                <CancelButton onClick={() => {
                  props.setShowModal(false);
                }} />
              </div>
              {props.msg && (
                <Button
                  sx={{ color: "red" }}
                  onClick={() => {
                    setShowDelete(true);
                  }}
                >
                  <DeleteIcon />
                </Button>
              )}
            </div>

            <br />
          </Modal>
        )}
        <br />

        <Modal
          show={showDelete}
          onHide={() => {
            setShowDelete(false);
          }}
        >
          <h4> {t("alert_sure")}</h4>

          <LoadingButton
            variant="contained"
            color="error"
            onClick={async () => {
              await props.handleUpdate(props.msg.id, "", "", "DELETE");
              props.setShowModal(false);
            }}
            loadingTxt={t("deleting")}
          >
            {t("delete")}
          </LoadingButton>

          <br />
          <button
            onClick={() => {
              setShowDelete(false);
            }}
          >
            {t("cancel")}
          </button>
        </Modal>
      </div>
    </>
  );
}

export default MsgModal;
