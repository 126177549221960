import "./TermsOfService.css";

const TermsOfService = () => {
    return (
        <>
            
            <div className="terms-container">
                <div className="terms-content">
                    <header className="header">
                        <h1>תנאי שימוש</h1>
                        <p>ביבוט - פתרונות צ'אטבוט מבוססי בינה מלאכותית</p>
                    </header>

                    <div className="main-content">
                        <section className="section">
                            <h2>1. מבוא ותחולה</h2>
                            <p>
                                ברוכים הבאים לשירות הצ'אטבוט החכם של ביבוט. מסמך זה מהווה הסכם משפטי מחייב בינך לבין חברת ביבוט בע"מ
                                (להלן: "החברה" או "ביבוט"). השימוש בשירותי הצ'אטבוט מהווה הסכמה מלאה לתנאים המפורטים במסמך זה.
                            </p>
                        </section>

                        <section className="section">
                            <h2>2. הגדרת השירות</h2>
                            <p>
                                ביבוט מספקת פלטפורמת צ'אטבוט מבוססת בינה מלאכותית, המאפשרת אינטראקציה אוטומטית בין בתי עסק ללקוחותיהם.
                                השירות מיועד לספק מענה ראשוני ומידע כללי, ואינו מהווה תחליף לשירות אנושי.
                            </p>

                            <div className="highlight-box">
                                <p>
                                    <strong>חשוב לדעת:</strong> השירות מבוסס על מערכות בינה מלאכותית מתקדמות, אך אינו מושלם ועשוי לכלול טעויות
                                    או אי-דיוקים.
                                </p>
                            </div>
                        </section>

                        <section className="section">
                            <h2>3. הגבלת אחריות</h2>
                            <div className="warning-box">
                                <p>
                                    ביבוט מספקת את השירות על בסיס "AS IS" ואינה מתחייבת לדיוק, שלמות או אמינות המידע המסופק.
                                    החברה לא תישא באחריות לכל נזק ישיר, עקיף, מקרי או תוצאתי הנובע משימוש בשירות.
                                </p>
                            </div>
                            <ul className="list">
                                <li>אין להסתמך על המידע המסופק כבסיס בלעדי לקבלת החלטות</li>
                                <li>יש לאמת כל מידע מהותי ישירות מול בית העסק</li>
                                <li>החברה אינה אחראית לתוכן התשובות המסופקות על ידי מערכת הבינה המלאכותית</li>
                                <li>השירות אינו מהווה ייעוץ מקצועי, משפטי, רפואי או פיננסי</li>
                            </ul>
                        </section>

                        <section className="section">
                            <h2>4. פרטיות ואבטחת מידע</h2>
                            <p>
                                השיחות עם הצ'אטבוט מתועדות ונשמרות במערכות החברה לצורך שיפור השירות. המידע מועבר דרך שרתים מאובטחים,
                                אך כמו בכל מערכת מקוונת, קיימים סיכוני אבטחה מובנים.
                            </p>
                            <p>
                                אנו ממליצים להימנע מהעברת מידע רגיש או אישי באמצעות הצ'אטבוט.
                            </p>
                        </section>

                        <section className="section">
                            <h2>5. שימוש הוגן</h2>
                            <p>
                                המשתמשים מתחייבים להשתמש בשירות באופן חוקי והוגן. חל איסור על:
                            </p>
                            <ul className="list">
                                <li>שימוש בשפה פוגענית או מאיימת</li>
                                <li>ניסיונות לפגוע או להשבית את המערכת</li>
                                <li>הפצת מידע שקרי או מטעה</li>
                                <li>שימוש בשירות למטרות בלתי חוקיות</li>
                            </ul>
                        </section>

                        <section className="section">
                            <h2>6. תמיכה ויצירת קשר</h2>
                            <p>
                                לתמיכה טכנית או בירורים, ניתן לפנות לבית העסק או ישירות לביבוט באמצעות:
                            </p>
                            <ul className="list">
                                <li>דוא"ל: support@genericgs.com</li>
                                <li>טופס יצירת קשר באתר החברה</li>
                            </ul>
                        </section>

                        <section className="section">
                            <h2>7. שינויים בתנאי השימוש</h2>
                            <p>
                                החברה שומרת לעצמה את הזכות לעדכן את תנאי השימוש מעת לעת. שינויים אלו ייכנסו לתוקף מיד עם פרסומם.
                                המשך השימוש בשירות לאחר עדכון התנאים מהווה הסכמה לתנאים המעודכנים.
                            </p>
                        </section>
                    </div>

                    <footer className="footer">
                        <p>© {new Date().getFullYear()} ביבוט בע"מ. כל הזכויות שמורות.</p>
                        <p>גרסה 1.3 | עודכן לאחרונה: 26.12.2024</p>
                        <a href='https://genericgs.com'> לדף הבית</a>
                    </footer>
                </div>
            </div>
        </>
    );
};

export default TermsOfService;