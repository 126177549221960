import React, { useContext, useEffect, useState } from "react";
import AddProduct from "./AddProduct";
import EditProductModal from "./EditProductModal";
import { globalContext } from "../../../App";
import { TextField } from "@mui/material";
import MainButton from "../../helpers/MainButton";
import { useTranslation } from "react-i18next";
import "./product.css";
import { getProducts } from "../../../clients/product-client";
import toast from "react-hot-toast";

function Products() {
  const [products, setProducts] = useState([])
  const [showAddPrd, setShowAddPrd] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("__all__");
  const [currentPage, setCurrentPage] = useState(1);

  const { storeData } = useContext(globalContext);

  const itemsPerPage = 10;

  const { t } = useTranslation();

  useEffect(() => {
    // fetch products from cache
    getProducts().then((res) => {      
      setProducts(res)
    }).catch((e) => {
      toast.error(t("loadProductsError"))
    })
  }, [])

  const refetchProducts = async () => {
    try {
      let _products = await getProducts(false);      
      setProducts(_products)
    } catch (error) {
      toast.error(t("loadProductsError"))
    }
  }

  // Calculate paginated products
  const filteredProducts = products?.filter((product) => {
    const nameMatch = product.name.toLowerCase().includes(searchTerm.toLowerCase());
    const categoryMatch =
      selectedCategory === "__all__" || selectedCategory === storeData.categories.find(c => c.id === product.category_id)?.name;
    return nameMatch && categoryMatch;
  }) || [];
  const totalPages = Math.ceil(filteredProducts.length / itemsPerPage);
  const paginatedProducts = filteredProducts.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <>
      {storeData && (
        <>
          <div className="products-container">
            <div className="products-search-container">
              {products?.length > 0 && (
                <>
                  <input
                    type="text"
                    className="search-field"
                    placeholder={t("free_search")}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <select
                    className="category-select"
                    value={selectedCategory}
                    onChange={(e) => setSelectedCategory(e.target.value)}
                  >
                    <option value="__all__">כל הקטגוריות</option>
                    {storeData.categories?.map((category) => (
                      <option key={category.id} value={category.name}>
                        {category.name}
                      </option>
                    ))}
                  </select>
                </>
              )}              
              <MainButton
                className="add-product-button"
                onClick={() => setShowAddPrd(true)}
              >
                {t("add_p")}
              </MainButton>
            </div>

            <div className="add-product-section">
              {showAddPrd && <AddProduct setShowAddPrd={setShowAddPrd} showAddPrd={showAddPrd} refetchProducts={refetchProducts} />}
            </div>

            {products?.length > 0 && (
              <div className="products-list-header only-pc">
                <div className="header-cell">{t("mkt")}</div>
                <div className="header-cell">{t("p_name")}</div>
                <div className="header-cell">{t("category")}</div>
                <div className="header-cell">{t("price")}</div>
                <div className="header-cell">{t("stk")}</div>
              </div>
            )}

            {paginatedProducts.map((p) => (
              <EditProductModal key={p.mkt} product={p} refetchProducts={refetchProducts} />
            ))}

            <div className="prd-pg-pagination">

              {totalPages > 1 && (
                  <div className="orders-page-pagination">
                    {Array.from({ length: totalPages }, (_, i) => (
                      <button
                        key={i}
                        className={`orders-page-pagination-button ${
                          currentPage === i + 1 ? "active" : ""
                        }`}
                        onClick={() => setCurrentPage(i + 1)}
                      >
                        {i + 1}
                      </button>
                    ))}
                  </div>
                )}
            </div>

          </div>
        </>
      )}
    </>
  );
}

export default Products;