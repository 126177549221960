export const SERVER_BASE_URL = 'https://genericgs.com/gs/api';
// export const SERVER_BASE_URL = "http://127.0.0.1:8000/gs/api";

// todo: remove the above, and use this:
export const GS_BASE_URL = 'https://genericgs.com/gs/api';
// export const GS_BASE_URL = "http://127.0.0.1:8000/gs/api";

export const BBOT_WS_URL = 'wss://genericgs.com/'
// export const BBOT_WS_URL = 'ws://localhost:8001/'

export const BBOT_REST_URL = 'https://genericgs.com/'
// export const BBOT_REST_URL = 'http://localhost:8001/'
