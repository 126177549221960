import React, { useEffect, useState } from "react";
import "./contact.css";
import ContactModal from "./ContactModal";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useTranslation } from "react-i18next";
import { getContacts } from "../../../clients/contacts-client";
import toast from "react-hot-toast";

function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [contactsPerPage] = useState(10); // Adjust the number of contacts per page as needed
  const [showModal, setShowModal] = useState(false);
  const [selectedContact, setSelectedContact] = useState("");

  const { t } = useTranslation();

  useEffect(() => {
    getContacts()
      .then((res) => setContacts(res))
      .catch(() => {
        toast.error(t("loadError"));
      });
  }, []);

  const refetchContacts = async () => {
    try {
      const _contacts = await getContacts(false);
      setContacts(_contacts);
    } catch (error) {
      toast.error(t("loadError"));
    }
  };

  const totalPages = Math.ceil(contacts.length / contactsPerPage);
  const startIndex = (currentPage - 1) * contactsPerPage;
  const currentContacts = contacts.slice(startIndex, startIndex + contactsPerPage);

  return (
    <>
      <div>
        {contacts && (
          <>
            <div
              style={{
                display: "flex",
                color: "var(--color1)",
                fontWeight: "700",
                margin: "1%",
                padding: "2%",
                textAlign: "center",
              }}
            >
              <p style={{ width: "10%", margin: 'auto' }}> {t("contactDone")} </p>
              <p style={{ width: "50%", margin: 'auto' }}> {t("date")} </p>
              <p style={{ width: "30%", margin: 'auto' }}> {t("orderClient")} </p>
            </div>
            {currentContacts.map((c) => (
              <div
                style={{ textAlign: "center" }}
                key={c.id}
                className="line1 gs-list"
                onClick={() => {
                  setSelectedContact(c);
                  setShowModal(true);
                }}
              >
                {c.status ? (
                  <CheckBoxIcon style={{ margin: "0 5%" }} />
                ) : (
                  <CheckBoxOutlineBlankIcon style={{ margin: "0 5%" }} />
                )}
                <p style={{ width: "40%", margin: 'auto' }}>
                  {c.created.slice(0, 10)} {c.created.slice(11, 16)}
                </p>
                <p style={{ width: "30%" }}> {c.name} </p>
              </div>
            ))}

            {contacts.length === 0 && <div> {t("noContacts")} </div>}

            {totalPages > 1 && (
              <div className="orders-page-pagination">
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i}
                    className={`orders-page-pagination-button ${
                      currentPage === i + 1 ? "active" : ""
                    }`}
                    onClick={() => setCurrentPage(i + 1)}
                  >
                    {i + 1}
                  </button>
                ))}
              </div>
            )}
          </>
        )}
      </div>

      {showModal && (
        <ContactModal
          showModal={showModal}
          setShowModal={setShowModal}
          contactId={selectedContact}
          contact={selectedContact}
          refetchContacts={refetchContacts}
        />
      )}
    </>
  );
}

export default Contacts;

// import React, { useEffect, useState } from "react";
// import "./contact.css";
// import ContactModal from "./ContactModal";
// import CheckBoxIcon from "@mui/icons-material/CheckBox";
// import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
// import { useTranslation } from "react-i18next";
// import { getContacts } from "../../../clients/contacts-client";
// import toast from "react-hot-toast";

// function Contacts() {
//   const [contacts, setContacts] = useState([])
//   const [showModal, setShowModal] = useState(false);
//   const [selectedContact, setSelectedContact] = useState("");

//   const { t } = useTranslation()
//   // const { storeData } = useContext(globalContext);

//   useEffect(() => {
//     // fetch contacts
//     getContacts().then((res) => { setContacts(res) }).catch(() => {
//       toast.error(t("loadError"))
//     })
//   }, [])

//   const refetchContacts = async () => {
//     // fetch w/o cache
//     try {
//       const _contacts = await getContacts(false)
//       setContacts(_contacts);
//     } catch (error) {
//       toast.error(t("loadError"))
//     }
//   }


//   return (
//     <>
//       <div>
//         {contacts && (
//           <>
//             <div
//               style={{
//                 display: "flex",
//                 color: "var(--color1)",
//                 fontWeight: "700",
//                 margin: "1%",
//                 padding: "2%",
//                 textAlign: 'center',

//               }}
//             >
//               <p style={{ width: "10%" }}> {t('contactDone')} </p>
//               <p style={{ width: "40%" }}> {t('date')} </p>
//               <p style={{ width: "30%" }}> {t('orderClient')} </p>
//             </div>
//             {contacts.sort().map((c) => {
//               return (
//                 <div
//                   style={{ textAlign: 'center' }}
//                   key={c.id}
//                   className="line1 gs-list"
//                   onClick={() => {
//                     setSelectedContact(c);
//                     setShowModal(true);
//                   }}
//                 >
//                   {c.status ? (
//                     <CheckBoxIcon style={{ margin: "0 5%" }} />
//                   ) : (
//                     <CheckBoxOutlineBlankIcon style={{ margin: "0 5%" }} />
//                   )}
//                   <p style={{ width: "40%" }}>
//                     {c.created.slice(0, 10)} {c.created.slice(11, 16)}
//                   </p>
//                   <p style={{ width: "30%" }}> {c.name} </p>
//                 </div>
//               );
//             })}

//             {contacts.length === 0 && <div> {t('noContacts')} </div>}
//           </>
//         )}
//       </div>

//       {showModal && (
//         <ContactModal
//           showModal={showModal}
//           setShowModal={setShowModal}
//           contactId={selectedContact}
//           contact={selectedContact}
//           refetchContacts={refetchContacts}
//         />
//       )}
//     </>
//   );
// }

// export default Contacts;
