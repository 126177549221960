import InfoIcon from "@mui/icons-material/Info";
import CategoryIcon from '@mui/icons-material/Category';
import LocalGroceryStoreTwoToneIcon from "@mui/icons-material/LocalGroceryStoreTwoTone";
import WatchLaterTwoToneIcon from "@mui/icons-material/WatchLaterTwoTone";
import NewspaperTwoToneIcon from "@mui/icons-material/NewspaperTwoTone";
import SmartToyIcon from '@mui/icons-material/SmartToy';
import ChatIcon from '@mui/icons-material/Chat';
import AirportShuttleTwoToneIcon from "@mui/icons-material/AirportShuttleTwoTone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContactSupportTwoToneIcon from "@mui/icons-material/ContactSupportTwoTone";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Settings } from "@mui/icons-material";
import { Modal } from "react-bootstrap";

import "./side-bar.css";
import { useTranslation } from "react-i18next";

function MobileMenu(props) {
  const nav = useNavigate();

  const { t } = useTranslation();
  const navTo = (target) => {
    nav(target);
    props.setShowModal(false);
  };
  return (
    <>
      <Modal
        show={props.showModal}
        onHide={() => {
          props.setShowModal(false);
        }}
        style={{ margin: "2%", padding: "3%", maxWidth: "90%" }}
      >
        <div style={{ backgroundColor: "var(--color3)" }}>
          <div
            style={{
              borderBottom: "solid var(--color1)",
            }}
          >
            <div
              style={{ backgroundColor: "var(--color2)", textAlign: "center", fontWeight: '600' }}
              onClick={() => { props.setShowModal(false) }}
            >
              {t("close")}
            </div>
            <mlink onClick={() => navTo("/about")}>
              <InfoIcon className="icon1" />
              {t("storeDetails")}
            </mlink>

            <mlink onClick={() => navTo("/products")}>
              <LocalGroceryStoreTwoToneIcon className="icon1" />
              {t("my_p")}
            </mlink>

            <mlink onClick={() => navTo("/chats")}>
              <ChatIcon className="icon1" />
              צ'טבוט
            </mlink>
            <mlink onClick={() => navTo("/categories")}>
              <CategoryIcon className="icon1" />
              קטגוריות
            </mlink>

            <mlink onClick={() => navTo("/openhours")}>
              <WatchLaterTwoToneIcon className="icon1" />
              {t("open_hours")}
            </mlink>

            <mlink onClick={() => navTo("/msgs")}>
              <SmartToyIcon className="icon1" />
              {t("msg_news")}
            </mlink>
            <mlink onClick={() => navTo("/calendar")}>
              <NewspaperTwoToneIcon className="icon1" />
              {t("calendar")}
            </mlink>

            <mlink onClick={() => navTo("/orders")}>
              <AirportShuttleTwoToneIcon className="icon1" />
              {t("orders")}
            </mlink>

            <mlink onClick={() => navTo("/contacts")}>
              <ContactSupportTwoToneIcon className="icon1" />
              {/* {t("contact")} */}
              תמיכה
            </mlink>

            <mlink onClick={() => navTo("/settings")}>
              <Settings className="icon1" />
              {t("settings")}
            </mlink>

            <mlink
              onClick={() => {
                localStorage.clear();
                window.location = "";
                nav("/");
              }}
            >
              <AccountCircleIcon className="icon1" />
              {t("logout")}
            </mlink>
            <div
              style={{ backgroundColor: "var(--color2)", textAlign: "center", fontWeight: '600' }}
              onClick={() => { props.setShowModal(false) }}
            >
              {t("close")}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default MobileMenu;
