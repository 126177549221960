import React, { useContext, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import ProductImage from "./ProductImage";
import { deleteProduct, updateProduct } from "../../../clients/product-client";
import { LoadingButton } from "../../helpers/LoadingButton";
import { globalContext } from "../../../App";
import AiDescModal from "../../AiDescModal";
import CheckIcon from "@mui/icons-material/Check";
import "./product.css";
import { Button } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { useTranslation } from "react-i18next";
import { CloseButton } from "react-bootstrap";
import CloseIcon from '@mui/icons-material/Close';
import { coinMap, getCategoryById } from "../../../helpers";
import toast from "react-hot-toast";
import CancelButton from "../../helpers/CancelButton";


function EditProductModal(props) {
  const { storeData } = useContext(globalContext);
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);
  const [category, setCategory] = useState();
  const [name, setName] = useState(props.product.name);
  const [price, setPrice] = useState(props.product.price);
  const [available, setAvailable] = useState(props.product.available);
  const [desc, setDesc] = useState(props.product.description);
  const [showImages, setShowImages] = useState(false);

  useEffect(() => {
    if (storeData.categories) {
      const cat = getCategoryById(props?.product?.category_id, storeData);
      setCategory(cat)
    }
    setDesc(props.product?.description)
  }, [])

  const handleDelete = async () => {
    const res = await deleteProduct(props.product.mkt);
    if (res.status === "ok") {
      await props.refetchProducts();
      setShowModal(false);
      return;
    }

    if (res.msg === 'orders attached')
      toast.error("ישנן הזמנות המשוייכות למוצר הזה. עליך למחוק אותן תחילה")
    else
      toast.error("אופס, אירעה  שגיאה. אנא נסה מאוחר יותר או פנה לתמיכה")
  };

  const handleEdit = async () => {
    // console.log(category);
    // if (!category.id) {
    //   alert("no category");
    //   return;
    // }
    // Ugly fix:
    const catId = typeof (category) === 'object' ? category.id : category;

    const res = await updateProduct({
      mkt: props.product.mkt,
      name: name,
      category_id: catId,
      price: price,
      description: desc,
      available: available ? "True" : "False",
    });
    if (res) {
      await props.refetchProducts();
      setShowModal(false);
    } else {
      toast.error("אופס. שגיאה התרחשה. אנא פנה לתמיכה או נסה שוב מאוחר יותרר")
    }
  };

  return (
    <div key={props.product.mkt}>
      <div
        className="gs-list"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <div className="only-pc" style={{ width: "25%" }}>
          {props.product.mkt}
          {props.product.images?.length > 0 && <ImageIcon />}
        </div>
        <div style={{ width: "30%" }}>{props.product.name}</div>
        <div style={{ width: "30%" }}>{getCategoryById(props?.product?.category_id, storeData).name}</div>


        <div style={{ width: "20%", textAlign: "center" }}>
          {props.product.price} {coinMap(storeData.coin)}
        </div>

        <div style={{ width: "20%" }}>
          {props.product.available && <CheckIcon />}
          {!props.product.available && <CloseIcon />}
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <div className="modal-title1">
          <h4>{t("edit_title")}</h4>
          <CloseButton onClick={() => setShowModal(false)} />
        </div>


        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label className="fl"> {t("p_name")} </Form.Label>
              <Form.Control
                value={name}
                type="text"
                autoFocus
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Form.Label className="fl"> {t("p_desc")} </Form.Label>
              </div>

              <Form.Control
                as="textarea"
                value={desc}
                rows={5}
                onChange={(e) => {
                  setDesc(e.target.value);
                }}
              />
            </Form.Group>
            <br />
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                justifyContent: "space-between",
              }}
            >
              <div style={{ maxWidth: "50%" }}>
                <label className="fl"> {t("category")} </label>

                <select
                  style={{ maxWidth: "100%" }}
                  value={category}
                  onChange={
                    (e) => setCategory(e.target.value)}
                >
                  {storeData.categories.map((cat) => (
                    <option key={cat.id} value={String(cat.id)}>
                      {cat.name}
                    </option>
                  ))}
                </select>

              </div>
              <div style={{ maxWidth: "23%" }}>
                <label className="fl" style={{ marginBottom: "2px" }}>
                  {t("price")} {coinMap(storeData.coin)}
                </label>
                <input
                  style={{ maxWidth: "100%" }}
                  type="number"
                  value={price}
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                />
              </div>

              <div
                style={{ textAlign: "center", marginLeft: "3%" }}
                onClick={() => {
                  setAvailable(!available);
                }}
              >
                <label className="fl"> {t("stk")}</label>
                {available && <CheckBoxIcon />}
                {!available && <CheckBoxOutlineBlankIcon />}
              </div>
            </div>
            <br />

            <label className="fl">{t("mkt")}</label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <input
                type="text"
                disabled
                value={props.product.mkt}
                style={{ minWidth: "45%" }}
              />

              <Button
                style={{ minWidth: "45%" }}
                sx={{ color: "var(--color1)" }}
                variant="outlined"
                onClick={() => {
                  setShowImages(true);
                  setShowModal(false);
                }}
              >
                {t("images")}
                <ImageIcon style={{ margin: "auto" }} />
              </Button>
            </div>
            <br />
          </Form>
        </Modal.Body>
        <hr style={{ color: "var(--color1)", margin: "2%" }} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            textAlign: "left",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              textAlign: "left",
              minWidth: "50%",
            }}
          >
            <LoadingButton
              sx={{
                margin: "2%",
                minWidth: "40%",
                backgroundColor: "var(--color2)",
              }}
              variant="contained"
              onClick={handleEdit}
              loadingTxt={t("updating")}
            >
              {t("update")}
            </LoadingButton>

            <CancelButton onClick={() => {
              setShowModal(false);
            }} />
          </div>

          <LoadingButton
            sx={{ margin: "2%" }}
            variant="outlined"
            color="error"
            onClick={handleDelete}
            loadingTxt={t("deleting")}
          >
            <DeleteIcon></DeleteIcon>
          </LoadingButton>
        </div>
      </Modal>

      <ProductImage
        showImageModal={showImages}
        product={props.product}
        handleClose={() => {
          setShowImages(false);
        }}
        setShowImages={setShowImages}
      />
    </div>
  );
}

export default EditProductModal;
