import React, { useContext, useEffect, useState } from 'react';
import { globalContext } from '../../../App';
import { updateStore } from "../../../helpers";
import { useTranslation } from 'react-i18next';
import "./Calendar.css"
import toast from 'react-hot-toast';


const Calendar = () => {
    const [btnDisabled, setBtnDisabled] = useState(true)
    const [formData, setFormData] = useState({
        calendarId: '',
        instructions: '',
        afterMessage: '',
        allow_calendar: false
    });

    const { storeData, setStoreData } = useContext(globalContext);
    const { t } = useTranslation();

    useEffect(() => {
        if (storeData) {
            setFormData({
                calendarId: storeData.calendar_id,
                instructions: storeData.calendar_instructions,
                afterMessage: storeData.calendar_after_msg,
                allow_calendar: storeData.allow_calendar
            })
        }
    }, [storeData]);

    const handleChange = (e) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData({
            ...formData,
            [e.target.name]: value
        });
        setBtnDisabled(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newData = {
            calendar_id: formData.calendarId,
            calendar_instructions: formData.instructions,
            calendar_after_msg: formData.afterMessage,
            allow_calendar: formData.allow_calendar
        }
        const newStoreData = {
            ...storeData,
            ...newData
        };

        await updateStore(newData);
        setStoreData(newStoreData);
        toast.success(t("saved"))
        setBtnDisabled(true);
    };

    return (
        <div className="calendar-container">
            <form onSubmit={handleSubmit}>
                <div className="calendar-checkbox-group">
                    <label className="calendar-checkbox-label">
                        <input
                            type="checkbox"
                            name="allow_calendar"
                            checked={formData.allow_calendar}
                            onChange={handleChange}
                        />
                        <span>{t("allowCalendar")}</span>
                    </label>
                </div>

                <div className="calendar-form-group">
                    <label htmlFor="calendarId">{t("calendarId")}</label>
                    <input
                        type="text"
                        id="calendarId"
                        name="calendarId"
                        value={formData.calendarId}
                        onChange={handleChange}
                        placeholder="יש להוציא מזהה יומן מתוך חשבון הגוגל שלך"
                        className="calendar-input"
                    />
                </div>

                <div className="calendar-form-group">
                    <label htmlFor="instructions">{t("calendarInstructions")}</label>
                    <textarea
                        id="instructions"
                        name="instructions"
                        value={formData.instructions}
                        onChange={handleChange}
                        rows={8}
                        placeholder={t("calendarInstructionsExample")}
                        className="calendar-textarea"
                    />
                </div>

                <div className="calendar-form-group">
                    <label htmlFor="afterMessage">{t("calendarEndMsg")}</label>
                    <textarea
                        id="afterMessage"
                        name="afterMessage"
                        value={formData.afterMessage}
                        onChange={handleChange}
                        placeholder={t("calendarEndMsgExample")}
                        className="calendar-textarea"
                    />
                </div>

                <button 
                    type="submit" 
                    disabled={btnDisabled}
                    className="calendar-submit-button"
                >
                    {t("save")}
                </button>
            </form>
        </div>
    );
};

export default Calendar;
// import React, { useContext, useEffect, useState } from 'react';
// import "./Calendar.css"
// import { globalContext } from '../../../App';
// import { updateStore } from "../../../helpers";
// import { useTranslation } from 'react-i18next';


// const Calendar = () => {
//     const [btnDisabled, setBtnDisabled] = useState(true)
//     const [formData, setFormData] = useState({
//         calendarId: '',
//         instructions: '',
//         afterMessage: ''
//     });

//     const { storeData, setStoreData } = useContext(globalContext);
//     const { t } = useTranslation();

//     useEffect(() => {
//         if (storeData) {
//             setFormData({
//                 calendarId: storeData.calendar_id,
//                 instructions: storeData.calendar_instructions,
//                 afterMessage: storeData.calendar_after_msg
//             })
//         }
//     }, [storeData]);


//     const handleChange = (e) => {
//         setFormData({
//             ...formData,
//             [e.target.name]: e.target.value
//         });
//         setBtnDisabled(false);
//     };



//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         // refetch storeData:
//         const newData = {
//             calendar_id: formData.calendarId,
//             calendar_instructions: formData.instructions,
//             calendar_after_msg: formData.afterMessage
//         }
//         const newStoreData = {
//             ...storeData,
//             ...newData
//         };

//         await updateStore(newData);
//         setStoreData(newStoreData);
//         setBtnDisabled(true);
//     };

//     return (
//         <div className="calendar-config">
//             <form onSubmit={handleSubmit}>
//                 <div className="form-group">
//                     <label htmlFor="calendarId">{t("calendarId")}</label>
//                     <input
//                         type="text"
//                         id="calendarId"
//                         name="calendarId"
//                         value={formData.calendarId}
//                         onChange={handleChange}
//                         placeholder="יש להוציא מזהה יומן מתוך חשבון הגוגל שלך"
//                     />
//                 </div>

//                 <div className="form-group">
//                     <label htmlFor="instructions">{t("calendarInstructions")}</label>
//                     <textarea
//                         id="instructions"
//                         name="instructions"
//                         value={formData.instructions}
//                         onChange={handleChange}
//                         rows={8}
//                         placeholder={t("calendarInstructionsExample")}
//                     />
//                 </div>

//                 <div className="form-group">
//                     <label htmlFor="afterMessage">{t("calendarEndMsg")}</label>
//                     <textarea
//                         id="afterMessage"
//                         name="afterMessage"
//                         value={formData.afterMessage}
//                         onChange={handleChange}
//                         placeholder={t("calendarEndMsgExample")}
//                     />
//                 </div>

//                 <button type="submit" disabled={btnDisabled}>{t("save")}</button>
//             </form>
//         </div>
//     );
// };

// export default Calendar;