import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import About from "./pages/About";
import Orders from "./pages/orders/Orders/Orders";
import Products from "./pages/Product/Products";
import Links from "./pages/links/Links";
import StoreImages from "./pages/storeImages/StoreImages";
import OpenHours from "./pages/openHours/OpenHours";
import Msgs from "./pages/msgs/Msgs";
import Contacts from "./pages/contacts/Contacts";
// import LoginSignup from "./LoginSignup";
import Account from "./pages/account/Account";
import Settings from "./pages/settings/Settings";
import ContactSystem from "./ContactSystem";
import Api from "./Api";
import Home from "./pages/home/Home";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
// import Signup from "./Signup";
import ChatsPage from "./pages/chatPage/ChatPage";
import Categories from "./pages/Categories/Categories"
import TermsOfService from "./TermsOfService/TermsOfService";
import Calendar from "./pages/calendar/Calendar";


function RouteSite() {
    
  return (
    <Routes>        
      <Route path="/login" element={<Login />} />              
      <Route path="/orders" element={<Orders />} />      
      <Route path="/" element={<Home />} />      
      <Route path="/about" element={<About />} />
      <Route path="/products" element={<Products />} />      
      <Route path="/openhours" element={<OpenHours />} />
      <Route path="/msgs" element={<Msgs />} />
      <Route path="/contacts" element={<Contacts />} />      
      <Route path="/settings" element={<Settings />} />      
      <Route path="/contact-system" element={<ContactSystem />} />            
      <Route path="/categories" element={<Categories />} />
      <Route path="/tos" element={<TermsOfService />} />
      <Route path="/chats" element={<ChatsPage />} />
      <Route path="/calendar" element={<Calendar />} />
    </Routes>
  );
}

export default RouteSite;
