import React, { useContext, useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { createProduct } from "../../../clients/product-client";
import { LoadingButton } from "../../helpers/LoadingButton";
import { globalContext } from "../../../App";
import { useTranslation } from "react-i18next";
import { coinMap } from "../../../helpers";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CancelButton from "../../helpers/CancelButton";


function AddProduct(props) {
  const { storeData, alertData, setAlertData } =
    useContext(globalContext);
  const { t } = useTranslation();
  const [category, setCategory] = useState(storeData.categories[0]?.id);
  const [name, setName] = useState("");
  const [price, setPrice] = useState(0);
  const [available, setAvailable] = useState(true);
  const [desc, setDesc] = useState("");
  const [mkt, setMkt] = useState(Date.now());

  const nav = useNavigate();

  useEffect(() => {
    if (storeData && Object.keys(storeData.categories).length === 0) {
      toast("עליך להגדיר לפחות קטגוריה אחת לפני שתוכל להוסיף מוצרים");
      nav("/categories");
    }
  }, [storeData])

  const handleAdd = async (e) => {
    e.preventDefault();

    if (name.length < 1) {
      // show alert msg
      const newAlertData = { ...alertData };
      newAlertData.msg = t("name_missing");
      newAlertData.active = true;
      setAlertData({ ...newAlertData });
      return;
    }

    await createProduct({
      mkt: mkt,
      name: name,
      category: category,
      price: price,
      description: desc,
      available: available ? "True" : "False",
    });
    
    await props.refetchProducts();
    props.setShowAddPrd(false);

    // show alert msg
    const newAlertData = { ...alertData };
    newAlertData.msg = t("p_added");
    newAlertData.active = true;
    setAlertData({ ...newAlertData });
  };

  return (
    <>
      {props.showAddPrd && (
        <>
          <Modal
            show={props.showAddPrd}
            onHide={() => {
              props.setShowAddPrd(false);
            }}
          >
            <Modal.Header style={{flexDirection: 'row-reverse', width: "70%"}} closeButton>
              <Modal.Title>
                {t("add_p_title")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <Form onSubmit={handleAdd}>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <label className="fl"> {t("mkt_uc")} </label>
                  <Form.Control
                    required
                    type="text"
                    value={mkt}
                    onChange={(e) => {
                      setMkt(e.target.value);
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3">
                  <label className="fl"> {t("category")} </label>
                  <select
                    style={{ maxWidth: "100%" }}
                    value={category}
                    onChange={
                      (e) => setCategory(e.target.value)}
                  >
                    {storeData.categories.map((cat) => (
                      <option key={cat.id} value={String(cat.id)}>
                        {cat.name}
                      </option>
                    ))}
                  </select>
                </Form.Group>

                <Form.Group className="mb-3">
                  <label className="fl">{t("name")} </label>
                  <Form.Control
                    required
                    value={name}
                    type="text"
                    autoFocus
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Form.Group>

                <label className="fl">
                  {t("price")} {coinMap(storeData.coin)}
                </label>
                <input
                  id="id_price"
                  type="number"
                  value={price}
                  onChange={(e) => {
                    setPrice(Number(e.target.value));
                  }}
                />

                <div>
                  <label className="fl"> {t("stk")}</label>
                  <input
                    style={{ marginRight: "5%" }}
                    required
                    id="id_available"
                    type="checkbox"
                    checked={available}
                    onChange={() => {
                      setAvailable(!available);
                    }}
                  />
                </div>
                <br></br>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <label className="fl"> {t("p_desc")} </label>
                  <Form.Control
                    as="textarea"
                    value={desc}
                    rows={3}
                    onChange={(e) => {
                      setDesc(e.target.value);
                    }}
                  />
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <CancelButton onClick={() => {
                  props.setShowAddPrd(false);
                }}/>

              <LoadingButton
                variant="contained"
                onClick={handleAdd}
                loadingTxt="מעדכן..."
              >
                {t("save")}
              </LoadingButton>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </>
  );
}

export default AddProduct;
