import { SERVER_BASE_URL } from "../config";
import axios from "axios";



export async function getContacts(useCache = true) {

  const storageKey = "store-contacts";

  // Check if we should purge and load from localStorage or request from the server
  if (useCache && localStorage.getItem(storageKey)) {
    const storedData = JSON.parse(localStorage.getItem(storageKey));

    // Check if data is older than 1 hour
    const currentTime = new Date().getTime();
    if (currentTime - storedData.timestamp < 3600000) {  // 3600000 ms = 1 hour      
      console.log("contacts loaded from localStorage");
      return storedData.results; // Return the data from localStorage
    }
    console.log("Cache expired, fetching new data");

  }

  try {
    const token = localStorage.getItem("logToken")
    const url = `${SERVER_BASE_URL}/contacts`;

    // Make the API request using axios
    const response = await axios.get(url, {
      headers: {
        'Authorization': `Token ${token}`,  // Replace with the actual token
        'Content-Type': 'application/json'
      }
    });

    // Save the fetched data to localStorage with timestamp
    const dataToStore = {
      results: response.data,
      timestamp: new Date().getTime()  // Save the timestamp
    };
    localStorage.setItem(storageKey, JSON.stringify(dataToStore));
    console.log("Contacts saved to localStorage:", dataToStore);

    return response.data;  // Return the server data
  } catch (error) {
    console.error("Error fetching orders:", error);
    return null; // Handle error as needed
  }
}

export const deleteContact = async (contactId) => {
  try {
    const token = localStorage.getItem("logToken");
    const options = {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",

    };
    const response = await axios.delete(`${SERVER_BASE_URL}/contact/delete/${contactId}`, options);
    return response.data;
  } catch (error) {
    console.error('Error deleting contact:', error);
    throw error;
  }
};

export const updateContactStatus = async (contactId, newStatus) => {
  const params = {
    contact_id: contactId,
    status: newStatus,
  };

  try {
    const options = {
      headers: {
        Authorization: `Token ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify(params),
    };
    const token = localStorage.getItem("logToken")
    const response = await axios.put('/contact/update', params);
    return response.data;
  } catch (error) {
    console.error('Error updating contact status:', error);
    throw error;
  }
};