import React, { useState, useEffect } from "react";
import Order from "./Order";
import { useTranslation } from "react-i18next";
import { getOrders } from "../../../../clients/orders-client";
import toast from "react-hot-toast";
import { LoadingButton } from "../../../helpers/LoadingButton";
import "./order.css"

function Orders() {
  const [orders, setOrders] = useState([])
  const [filter, setFilter] = useState(false);  
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 10;
  
  const { t } = useTranslation();

  useEffect(() => {
    getOrders()
      .then((res) => {
        setOrders(res);
        const _totalPages = Math.ceil(
          res.filter(
            (order) => (!filter || order.status === filter) && searchOrders(order)
          ).length / itemsPerPage
        );
        setTotalPages(_totalPages);
      })
      .catch((e) => {
        console.log(e);
        toast.error("שגיאה אירעה בעת טעינת ההזמנות. אנא פנה לתמיכה או נסה שוב מאוחר יותר");
      });
  }, []);
  
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]); // Reset currentPage to 1 whenever searchQuery changes

  const filterByStatus = (status) => {
    if (status === "- -") {
      setFilter(false);
    } else {
      setFilter(status);
    }
  };

  const searchOrders = (order) => {
    if (!searchQuery) return true;
    const searchLower = searchQuery.toLowerCase();

    // reformat client_phone 9725466.. ->  05466..
    const modifiedNumber = order.client_telephone.replace('972', '0');

    return (
      order.client_name.toLowerCase().includes(searchLower) ||
      order.order_id.toLowerCase().includes(searchLower) ||
      order.client_telephone.includes(searchLower) ||
      modifiedNumber.includes(searchLower)
    );
  };

  const paginatedOrders = (orders) => {
    const start = (currentPage - 1) * itemsPerPage;
    return orders.slice(start, start + itemsPerPage);
  };  

  const refreshOrders = async () => {
    // fetch orders with cache=false
    const updatedOrders = await getOrders(false);
    setOrders(updatedOrders);
  }
  return (
    <>
      <LoadingButton className="only-mobile orders-refresh" onClick={refreshOrders}> {t('refresh')}</LoadingButton>      
      {orders && (
        <div className="orders-page-container">
          {orders.length > 1 && (
            <div className="orders-page-filter-container">
              
              <select
                className="orders-page-filter-select"
                name="status"
                id="id_status"
                onChange={(e) => filterByStatus(e.target.value)}
              >
                <option value="- -">{t("filterAllOrders")}</option>
                <option value="done">{t("finishOrders")}</option>
                <option value="pending">{t("waitingOrders")}</option>
                <option value="decline">{t("rejectOrders")}</option>
              </select>
              <input
                type="text"
                className="orders-page-search-box"
                placeholder={t("searchOrders")}
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <LoadingButton className='only-pc' onClick={refreshOrders}> {t('refresh')}</LoadingButton>      
            </div>
          )}
          <br className="only-pc" />
          <div className="orders-page-header only-pc">
            <p className="orders-page-header-item"> {t("orderNum")} </p>
            <p className="orders-page-header-item"> {t("orderClient")} </p>
            <p className="orders-page-header-item"> {t("clientPhone")} </p>
            <p className="orders-page-header-item"> {t("date")} </p>
          </div>
          <div>
            {orders && (
              <>
                {orders.length === 0 && <div>{t("noOrders")}</div>}
                <div className="orders-page-list">
                  {paginatedOrders(
                    orders.filter(
                      (order) =>
                        (!filter || order.status === filter) &&
                        searchOrders(order)
                    )
                  ).map((order) => (
                    <Order key={order.id} order={order} refreshOrders={refreshOrders} />
                  ))}
                </div>
                {totalPages > 1 && (
                  <div className="orders-page-pagination">
                    {Array.from({ length: totalPages }, (_, i) => (
                      <button
                        key={i}
                        className={`orders-page-pagination-button ${
                          currentPage === i + 1 ? "active" : ""
                        }`}
                        onClick={() => setCurrentPage(i + 1)}
                      >
                        {i + 1}
                      </button>
                    ))}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Orders;
