import React, { useEffect, useRef, useState } from 'react';
import './AnimatedDemoChat.css';

const AnimatedDemoChat = () => {
    const [visibleMessages, setVisibleMessages] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const messagesContainerRef = useRef(null);
    const messagesEndRef = useRef(null);

    const messages = [
        {
            from: "user",
            msg: "שלום",
            time: "10:01",
            delay: 0,
        },
        {
            from: "bot",
            msg: "👋 שלום! ברוך הבא לשירות האופטיקה שלנו. איך אוכל לעזור לך היום?",
            time: "10:00",
            delay: 5000,
        },
        {
            from: "user",
            msg: "שלום, אני מעוניין לקבוע תור לבדיקת ראייה",
            time: "10:01",
            delay: 5000,
        },
        {
            from: "bot",
            msg: "בשמחה! אני יכול לעזור לך בזה. לפני שנקבע תור, האם יש לך כבר מרשם עדכני למשקפיים או עדשות מגע?",
            time: "10:02",
            delay: 5000,
        },
        {
            from: "user",
            msg: "לא, אני צריך בדיקת ראייה חדשה",
            time: "10:03",
            delay: 5000,
        },
        {
            from: "bot",
            msg: "הבנתי. אני אשמח לקבוע לך תור לבדיקת ראייה. האם יש לך תאריך או שעה מועדפים?",
            time: "10:04",
            delay: 5000,
        },
        {
            from: "user",
            msg: "אשמח לקבוע תור ליום חמישי הקרוב, בשעות הבוקר",
            time: "10:05",
            delay: 5000,
        },
        {
            from: "bot",
            msg: "אני בודק את הזמינות... יש לנו תור פנוי ביום חמישי בשעה 10:00 בבוקר. האם זה מתאים לך?",
            time: "10:06",
            delay: 5000,
        },
        {
            from: "user",
            msg: "כן, זה מצוין",
            time: "10:07",
            delay: 5000,
        },
        {
            from: "bot",
            msg: "מצוין! קבעתי לך תור ליום חמישי בשעה 10:00. האם תרצה לקבל תזכורת בסמס או במייל לפני התור?",
            time: "10:08",
            delay: 5000,
        },
        {
            from: "user",
            msg: "כן, אשמח לקבל תזכורת בסמס",
            time: "10:09",
            delay: 5000,
        },
        {
            from: "bot",
            msg: "בסדר גמור! תזכורת תשלח אליך בסמס יום לפני התור. האם יש משהו נוסף שאוכל לעזור לך בו? אולי מידע על משקפיים או עדשות מגע?",
            time: "10:10",
            delay: 5000,
        },
        {
            from: "user",
            msg: "כן, אשמח לדעת מה המחירים של משקפי שמש בסיסיים",
            time: "10:11",
            delay: 5000,
        },
        {
            from: "bot",
            msg: "כמובן! משקפי שמש בסיסיים מתחילים מ-150 ש״ח ועד 400 ש״ח, תלוי במותג ובעיצוב. אנו נושאים מותגים כמו Ray-Ban, Oakley ו-Carrera. האם תרצה לדעת על מותג ספציפי?",
            time: "10:12",
            delay: 5000,
        },
        {
            from: "user",
            msg: "כן, מה המחירים של Ray-Ban?",
            time: "10:13",
            delay: 5000,
        },
        {
            from: "bot",
            msg: "משקפי שמש של Ray-Ban מתחילים מ-400 ש״ח ועד 800 ש״ח, בהתאם לדגם. יש לנו מבחר גדול בחנות. האם תרצה לקבל מידע נוסף על דגם מסוים?",
            time: "10:14",
            delay: 5000,
        },
        {
            from: "user",
            msg: "לא תודה, זה מספיק בינתיים",
            time: "10:15",
            delay: 5000,
        },
        {
            from: "bot",
            msg: "בסדר גמור! אם יהיה לך צורך בעוד מידע או עזרה, אנא אל תהסס לפנות. שיהיה לך יום מצוין! 😊",
            time: "10:16",
            delay: 5000,
        },
    ];

    const scrollToBottom = () => {
        if (messagesContainerRef.current) {
            const scrollHeight = messagesContainerRef.current.scrollHeight;
            const height = messagesContainerRef.current.clientHeight;
            const maxScrollTop = scrollHeight - height;
            
            messagesContainerRef.current.scrollTo({
                top: maxScrollTop,
                behavior: 'smooth'
            });
        }
    };

    useEffect(() => {
        let totalDelay = 0;
        
        messages.forEach((message, index) => {
            totalDelay += message.delay;
            
            // Show typing indicator before bot messages
            if (message.from === "bot") {
                setTimeout(() => setIsTyping(true), totalDelay - 1000);
                setTimeout(() => setIsTyping(false), totalDelay - 200);
            }
            
            setTimeout(() => {
                setVisibleMessages(prev => [...prev, message]);
            }, totalDelay);
        });
    }, []);

    useEffect(() => {
        scrollToBottom();
    }, [visibleMessages]);

    return (
        <div className="adc-container" dir="rtl">
            <div className="adc-header">
                <h3>צ'אט עם נציג שירות לדוגמה</h3>
            </div>
            
            <div className="adc-messages" ref={messagesContainerRef}>
                {visibleMessages.map((message, index) => (
                    <div 
                        key={index} 
                        className={`adc-message ${message.from === 'bot' ? 'adc-bot' : 'adc-user'} visible`}
                    >
                        <div className="adc-bubble">
                            {message.msg}
                            <span className="adc-time">{message.time}</span>
                        </div>
                    </div>
                ))}
                
                {isTyping && (
                    <div className="adc-typing visible">
                        <div className="adc-typing-dot"></div>
                        <div className="adc-typing-dot"></div>
                        <div className="adc-typing-dot"></div>
                    </div>
                )}
                <div ref={messagesEndRef} />
            </div>
            
            <div className="adc-input">
                <input 
                    disabled
                    type="text" 
                    placeholder="הקלד/י הודעה..."
                    className="adc-input-field"
                />
                <button className="adc-send-btn" disabled>שלח</button>
            </div>
        </div>
    );
};

export default AnimatedDemoChat;